import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField
} from "@mui/material";
import "./dialog.css";
import { Theme, useTheme } from "@mui/material";
import ErrorDisplay from "../../error-display";
import { AnnouncementAPIRequest, announcementMessageType, getAnnouncementMessageTypes } from "../../../../models/announcement";
import SubmitButtonsDialogCreateAnnouncement from "../submit-buttons/submit-button-create-announcement";
import { useEffect, useState } from "react";
import SelectComponent from "../../select-component";
import { timestampStringBeforeCurrentDate } from "../../../../helper/validation";
import dayjs from "dayjs";

export interface DeleteProps {
    dialogInfo: string;
    dialogTitle: string;
    showDialog: boolean;
    onClickSubmit: (value: AnnouncementAPIRequest) => void;
    onClickCancel: () => void;
}

const CreateAnnouncementDialog = ({
    showDialog,
    dialogInfo,
    dialogTitle,
    onClickSubmit,
    onClickCancel,
}: DeleteProps) => {
    const theme: Theme = useTheme();
    const [message, setMessage] = useState<string>("");
    const [from, setFrom] = useState<string>("");
    const [until, setUntil] = useState<string>("");
    const [announcementMessageType, setAnnouncementMessageType] = useState<announcementMessageType | null>(null);

    useEffect(() => {
        setMessage("");
        setAnnouncementMessageType(null)
        const current = dayjs().set("hour", 1).set("minute", 0).set("second", 1).add(1, "day");
        setFrom(current.toISOString().substring(0, 16))
        setUntil(current.toISOString().substring(0, 16))
    }, [])
    return (
        <Dialog open={showDialog}>
            <DialogTitle
                sx={{ backgroundColor: theme.palette.primary.main, color: "white" }}
            >
                {dialogTitle}
            </DialogTitle>
            <DialogContent sx={{ marginTop: "1rem" }} className="DialogContent">
                <ErrorDisplay>
                    <DialogContentText>
                        <div className="dialog-content-text">
                            <p>{dialogInfo}</p>
                            <div className="dialog-text-fields">
                                <TextField
                                    id="message"
                                    label="Nachricht"
                                    type='text'
                                    value={message}
                                    className="single-view-employee__text-field"
                                    variant="outlined"
                                    error={message === ""}
                                    helperText={
                                        message === ""
                                            ? "Die Meldung benötigt eine Nachricht!"
                                            : ""
                                    }
                                    sx={{
                                        width: "500px",
                                        marginBottom: "1rem"
                                    }}
                                    multiline
                                    onChange={(event) => {
                                        setMessage(event.target.value);
                                    }}
                                />
                                <SelectComponent
                                    selectData={getAnnouncementMessageTypes()}
                                    tooltip="Es wurden noch keine Mitteilungs-Typen angelegt."
                                    className="single-view__text-field"
                                    label="Mitteilungs-Typ"
                                    value={announcementMessageType?.name || ""}
                                    onChange={(value: string) => setAnnouncementMessageType(getAnnouncementMessageTypes().find(({ name }) => name === value) || null)}
                                    disabled={false}
                                    error={!announcementMessageType}
                                ></SelectComponent>
                                <TextField
                                    id="from"
                                    label="Von-Datum"
                                    type='datetime-local'
                                    value={from}
                                    className="single-view-employee__text-field"
                                    variant="outlined"
                                    error={from === "" || timestampStringBeforeCurrentDate(from)}
                                    helperText={
                                        from === ""
                                            ? "Das Von-Datum muss gesetzt sein!"
                                            : timestampStringBeforeCurrentDate(from)
                                                ? "Das Von-Datum darf nicht vor dem aktuellen Datum liegen!"
                                                : ""
                                    }
                                    sx={{
                                        width: "500px",
                                        marginTop: "1rem",
                                        marginBottom: "1rem"
                                    }}
                                    onChange={(event) => {
                                        setFrom(event.target.value);
                                    }}
                                />
                                <TextField
                                    id="until"
                                    label="Bis-Datum"
                                    type='datetime-local'
                                    value={until}
                                    className="single-view-employee__text-field"
                                    variant="outlined"
                                    error={until === "" || until < from || timestampStringBeforeCurrentDate(until)}
                                    helperText={
                                        until === ""
                                            ? "Das Bis-Datum muss gesetzt sein!"
                                            : until < from
                                                ? "Das Bis-Datum darf nicht vor dem Von-Datum liegen!"
                                                : timestampStringBeforeCurrentDate(until)
                                                    ? "Das Bis-Datum darf nicht vor dem aktuellen Datum liegen!"
                                                    : ""
                                    }
                                    sx={{
                                        width: "500px",
                                        marginBottom: "1rem"
                                    }}
                                    onChange={(event) => {
                                        setUntil(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                    </DialogContentText>
                </ErrorDisplay>
            </DialogContent>
            <DialogActions>
                <SubmitButtonsDialogCreateAnnouncement
                    submitText="ERSTELLEN"
                    cancelText="ABBRECHEN"
                    onClickSubmit={onClickSubmit}
                    onClickCancel={onClickCancel}
                    value={{
                        announcementType: "",
                        message,
                        announcementMessageType: announcementMessageType ? announcementMessageType.id : "",
                        from,
                        until
                    }}
                />
            </DialogActions>
        </Dialog>
    );
};

export default CreateAnnouncementDialog;
