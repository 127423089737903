import { UserAPI } from "./user";

export type AnnouncementAPI = {
    id: string;
    announcementType: string;
    announcementMessageType: string;
    user: UserAPI;
    message: string;
    from: string;
    until: string;
}

export type AnnouncementAPIRequest = {
    announcementType: string;
    announcementMessageType: string;
    message: string;
    from: string;
    until: string;
}

export type announcementMessageType = {
    id: string;
    name: string;
}

export const getAnnouncementMessageTypes = () => {
    return [
        {
            id: "E_ANNOUNCEMENT_TYPE_INFO",
            name: "Information",
        },
        {
            id: "E_ANNOUNCEMENT_TYPE_WARNING",
            name: "Warnung",
        }
    ]
}