import { resourceAxiosInstance } from "../../config/axios.config";
import { AnnouncementAPI, AnnouncementAPIRequest } from "../../models/announcement";

export const fetchAnnouncements = () =>
    resourceAxiosInstance.get<AnnouncementAPI[]>(`/announcement`, {
        cache: false,
    });

export const createAnnouncement = (announcement: Partial<AnnouncementAPIRequest>) =>
    resourceAxiosInstance.post<AnnouncementAPI>(`/announcement`, announcement, {
        cache: false,
    });

export const deleteAnnouncement = (announcementId: string) =>
    resourceAxiosInstance.delete(`/announcement/${announcementId}`, {
        cache: false,
    });