import { axiosInstance, resourceAxiosInstance } from "../../config/axios.config";
import { InstitutionAPI, InstitutionAPIRequest } from "../../models/institution";

export const fetchInstitutions = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution`, {
        id: '/institution'
    });

export const fetchInstitutionsWithSystem = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?system=true`, {
        id: '/institution?system=true'
    });

export const fetchInstitutionsWithSystemWithoutPensionInstitutions = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?system=true&pensionInstitutions=false`, {
        id: '/institution?system=true&pensionInstitutions=false'
    });

export const fetchInsurances = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=false&insurances=true&pensionInstitutions=false`, {
        id: '/institution?aids=false&insurances=true&pensionInstitutions=false'
    });

export const fetchAids = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=true&insurances=false&pensionInstitutions=false`, {
        id: '/institution?aids=true&insurances=false&pensionInstitutions=false'
    });

export const fetchPensionInstitutions = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=false&insurances=false&pensionInstitutions=true`, {
        id: '/institution?aids=false&insurances=false&pensionInstitutions=true'
    });

export const fetchAidsAndInsurances = () =>
    resourceAxiosInstance.get<InstitutionAPI[]>(`/institution?aids=true&insurances=true&pensionInstitutions=false`, {
        id: '/institution?aids=true&insurances=true&pensionInstitutions=false'
    });


export const importAids = (content: string) => {
    return axiosInstance.post(`/institution/import/aid`, content,
        {
            cache: {
                update: {
                    '/institution?aids=true&insurances=false&pensionInstitutions=false': 'delete',
                    '/institution?aids=false&insurances=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true': 'delete',
                    '/institution': 'delete',
                    '/institution?aids=false&insurances=false&pensionInstitutions=true': 'delete',
                    '/institution?aids=true&insurances=true&pensionInstitutions=false': 'delete',
                }
            },
            headers: {
                'Content-Type': 'text/plain'
            },
        },);
}

export const importInsurances = (content: string) => {
    return axiosInstance.post(`/institution/import/insurance`, content,
        {
            cache: {
                update: {
                    '/institution?aids=true&insurances=false&pensionInstitutions=false': 'delete',
                    '/institution?aids=false&insurances=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true': 'delete',
                    '/institution': 'delete',
                    '/institution?aids=false&insurances=false&pensionInstitutions=true': 'delete',
                    '/institution?aids=true&insurances=true&pensionInstitutions=false': 'delete',
                }
            },
            headers: {
                'Content-Type': 'text/plain'
            },
        },);
}

export const importPensionInstitutions = (content: string) => {
    return axiosInstance.post(`/institution/import/pension-institution`, content,
        {
            cache: {
                update: {
                    '/institution?aids=true&insurances=false&pensionInstitutions=false': 'delete',
                    '/institution?aids=false&insurances=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true': 'delete',
                    '/institution': 'delete',
                    '/institution?aids=false&insurances=false&pensionInstitutions=true': 'delete',
                    '/institution?aids=true&insurances=true&pensionInstitutions=false': 'delete',
                }
            },
            headers: {
                'Content-Type': 'text/plain'
            },
        },);
}

export const updateInstitution = (institution: Partial<InstitutionAPIRequest>) =>
    resourceAxiosInstance.patch<InstitutionAPIRequest>(
        `/institution/${institution.id}`,
        institution,
        {
            cache: {
                update: {
                    '/institution?aids=true&insurances=false&pensionInstitutions=false': 'delete',
                    '/institution?aids=false&insurances=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true': 'delete',
                    '/institution': 'delete',
                    '/institution?aids=false&insurances=false&pensionInstitutions=true': 'delete',
                    '/institution?aids=true&insurances=true&pensionInstitutions=false': 'delete',
                }
            },
        }
    );

export const changeActiveOfInstitution = (institutionId: string | undefined) =>
    resourceAxiosInstance.put<InstitutionAPI>(
        `/institution/${institutionId}/active`,
        {
            cache: {
                update: {
                    '/institution?aids=true&insurances=false&pensionInstitutions=false': 'delete',
                    '/institution?aids=false&insurances=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true': 'delete',
                    '/institution': 'delete',
                    '/institution?aids=false&insurances=false&pensionInstitutions=true': 'delete',
                    '/institution?aids=true&insurances=true&pensionInstitutions=false': 'delete',
                }
            },
        }
    );

export const deleteInstitution = (institutionId: string | undefined) =>
    resourceAxiosInstance.delete(`/institution/${institutionId}`,
        {
            cache: {
                update: {
                    '/institution?aids=true&insurances=false&pensionInstitutions=false': 'delete',
                    '/institution?aids=false&insurances=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true': 'delete',
                    '/institution': 'delete',
                    '/institution?aids=false&insurances=false&pensionInstitutions=true': 'delete',
                    '/institution?aids=true&insurances=true&pensionInstitutions=false': 'delete',
                }
            },
        });

export const fetchInstitution = (institutionId: string) =>
    resourceAxiosInstance.get<InstitutionAPI>(`/institution/${institutionId}`, {
        cache: false,
    });

export const createInstitution = (institution: Partial<InstitutionAPIRequest>) =>
    resourceAxiosInstance.post<InstitutionAPIRequest>(`/institution`, institution,
        {
            cache: {
                update: {
                    '/institution?aids=true&insurances=false&pensionInstitutions=false': 'delete',
                    '/institution?aids=false&insurances=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true&pensionInstitutions=false': 'delete',
                    '/institution?system=true': 'delete',
                    '/institution': 'delete',
                    '/institution?aids=false&insurances=false&pensionInstitutions=true': 'delete',
                    '/institution?aids=true&insurances=true&pensionInstitutions=false': 'delete',
                }
            },
        });
