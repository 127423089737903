import axios from "axios";
import { HttpStatusCode } from "../enums/http-status-code";
import { logout, logoutRequest } from "../redux/auth.reducer";
import { StoreState } from "../redux/store";
import { setError } from "../redux/error.reducer";
import { setupCache } from "axios-cache-interceptor";
import { registerRequest, unregisterRequest } from "../redux/spinner.reducer";

let store: StoreState;

export const injectStore = (_store: StoreState) => {
    store = _store;
};

const _axiosInstanceNoWait = axios.create({
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    withCredentials: true,
    withXSRFToken: true,
    baseURL: process.env.REACT_APP_BASE_URL,
});

const _axiosInstance = axios.create({
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    withCredentials: true,
    withXSRFToken: true,
    baseURL: process.env.REACT_APP_BASE_URL,
});

const _resourceAxiosInstance = axios.create({
    xsrfCookieName: "XSRF-TOKEN",
    xsrfHeaderName: "X-XSRF-TOKEN",
    withCredentials: true,
    withXSRFToken: true,
    baseURL: process.env.REACT_APP_BASE_URL,
});

const delayInMs = 130;

const errorHandling = (error: any) => {
    console.log(error)
    const { status, data } = error.response;
    if (status === HttpStatusCode.Unauthorized && typeof logout === "function") {
        store.dispatch(logoutRequest());
    } else {
        store.dispatch(setError(data.message));
    }
    store.dispatch(unregisterRequest());

    return Promise.reject(error);
};

_resourceAxiosInstance.interceptors.request.use((config) => {
    config.baseURL = `${config.baseURL}`;
    config.data = { ...config.data };
    // delayed register to avoid loading page for fast requests
    setTimeout(() => store.dispatch(registerRequest()), delayInMs);
    return config;
});

_resourceAxiosInstance.interceptors.response.use((response) => {
    store.dispatch(unregisterRequest());
    return response;
}, errorHandling);

_axiosInstance.interceptors.request.use((config) => {
    // delayed register to avoid loading page for fast requests
    setTimeout(() => store.dispatch(registerRequest()), delayInMs);
    return config;
});

_axiosInstance.interceptors.response.use((response) => {
    store.dispatch(unregisterRequest());
    return response;
}, errorHandling);

const axiosInstanceNoWait = setupCache(_axiosInstanceNoWait, {
    debug: console.log,
    interpretHeader: false,
});

const axiosInstance = setupCache(_axiosInstance, {
    debug: console.log,
    interpretHeader: false,
});
const resourceAxiosInstance = setupCache(_resourceAxiosInstance, {
    debug: console.log,
    interpretHeader: false,
});

export { axiosInstance, resourceAxiosInstance, axiosInstanceNoWait };
