import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import "../details.css"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { } from "../../../redux/auth.reducer";
import PageLayout from "../../../shared/components/page-layout";
import { Announcement, Assignment, AssistWalker, Delete, Info, Person, Warning } from "@mui/icons-material";
import { clearHistory } from "../../../models/history";
import { hasGetPersonDataPermission, timestampStringBeforeCurrentDate } from "../../../helper/validation";
import { fetchAllProcesses } from "../../../shared/requests/user.requests";
import { ProcessAPI } from "../../../models/user";
import AccordionComponent from "../../../shared/components/accordion-component";
import { AnnouncementAPI, AnnouncementAPIRequest } from "../../../models/announcement";
import { createAnnouncement, deleteAnnouncement, fetchAnnouncements } from "../../../shared/requests/announcement-request";
import ConfirmCancelDialog from "../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog";
import CreateAnnouncementDialog from "../../../shared/components/standard-actions/dialogs/create-announcement-dialog";
import { formatDateTimeString } from "../../../helper/formatter";

function Home() {
    const [institutionOpenProcesses, setInstitutionOpenProcesses] = useState<ProcessAPI[]>([]);
    const [institutionNotImportedProcesses, setInstitutionNotImportedProcesses] = useState<ProcessAPI[]>([]);
    const [announcements, setAnnouncements] = useState<AnnouncementAPI[]>([]);
    const [showDeleteAnnouncementDialog, setShowDeleteAnnouncementDialog] = useState<boolean>(false);
    const [showAddSystemAnnouncementDialog, setShowSystemAddAnnouncementDialog] = useState<boolean>(false);
    const [showAddInstitutionAnnouncementDialog, setShowInstitutionAddAnnouncementDialog] = useState<boolean>(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState<AnnouncementAPI | null>(null);
    const isInitRender = useRef(true);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (isInitRender.current && user) {
            fetchAllProcesses().then((response) => {
                const openProcesses: ProcessAPI[] = []
                const notImportedProcesses: ProcessAPI[] = []
                response.data.forEach((element) => {
                    if (element.user.id === user.id && element.processStatus === "E_PROCESS_STATUS_OPEN") {
                        openProcesses.push(element);
                    }
                    if (element.processStatus === "E_PROCESS_STATUS_NOT_IMPORTED") {
                        notImportedProcesses.push(element);
                    }
                });
                setInstitutionOpenProcesses(openProcesses);
                setInstitutionNotImportedProcesses(notImportedProcesses);
            });
            fetchAnnouncements().then(response => {
                setAnnouncements(response.data.sort((a, b) => a.from > b.from ? -1 : 1))
            })
            clearHistory();
            isInitRender.current = false;
        }
    });
    const navigateToPersonInNeedOfCare = () => {
        navigate(`/person-in-need-of-care`);
    }
    const navigateToCaringPerson = () => {
        navigate(`/caring-person`);
    }
    const navigateToProcesses = () => {
        navigate(`/process`);
    }

    const onClickAddSystemAnnouncement = () => {
        setShowSystemAddAnnouncementDialog(true)
    }

    const onClickAddInstitutionAnnouncement = () => {
        setShowInstitutionAddAnnouncementDialog(true)
    }

    const onAddInstitutionAnnouncementSubmit = (announcement: AnnouncementAPIRequest) => {
        if (announcement.message !== "" && announcement.announcementType !== "" && announcement.from !== "" && announcement.until !== "" && announcement.from <= announcement.until && !timestampStringBeforeCurrentDate(announcement.until) && !timestampStringBeforeCurrentDate(announcement.from)) {
            const announcementsNew = announcements;
            announcement.from = announcement.from.replaceAll("T", " ")
            announcement.until = announcement.until.replaceAll("T", " ")
            announcement.announcementType = "E_ANNOUNCEMENT_TYPE_INSTITUTION";
            createAnnouncement(announcement).then(response => {
                announcementsNew.push(response.data)
                setShowInstitutionAddAnnouncementDialog(false)
                setAnnouncements(announcementsNew.sort((a, b) => a.from > b.from ? -1 : 1))
            })
        }
    }

    const onAddSystemAnnouncementSubmit = (announcement: AnnouncementAPIRequest) => {
        if (announcement.message !== "" && announcement.announcementType !== "" && announcement.from !== "" && announcement.until !== "" && announcement.from <= announcement.until && !timestampStringBeforeCurrentDate(announcement.until) && !timestampStringBeforeCurrentDate(announcement.from)) {
            const announcementsNew = announcements;
            announcement.from = announcement.from.replaceAll("T", " ")
            announcement.until = announcement.until.replaceAll("T", " ")
            announcement.announcementType = "E_ANNOUNCEMENT_TYPE_SYSTEM";
            createAnnouncement(announcement).then(response => {
                announcementsNew.push(response.data)
                setShowSystemAddAnnouncementDialog(false)
                setAnnouncements(announcementsNew.sort((a, b) => a.from > b.from ? -1 : 1))
            })
        }
    }

    const callOnAnnouncementDelete = (announcement: AnnouncementAPI) => {
        setSelectedAnnouncement(announcement);
        setShowDeleteAnnouncementDialog(true)
    }

    const onAnnouncementCancel = () => {
        setShowSystemAddAnnouncementDialog(false)
        setShowDeleteAnnouncementDialog(false)
        setShowInstitutionAddAnnouncementDialog(false)
    }

    const onAnnouncementDeleteSubmit = () => {
        if (selectedAnnouncement) {
            deleteAnnouncement(selectedAnnouncement.id).then(() => {
                const announcementsNew: AnnouncementAPI[] = [];
                announcements.forEach(element => {
                    if (element.id !== selectedAnnouncement.id) {
                        announcementsNew.push(element);
                    }
                })
                setAnnouncements(announcementsNew.sort((a, b) => a.from > b.from ? -1 : 1))
                setShowDeleteAnnouncementDialog(false)
            });
        }
    }

    return <PageLayout title={"Home"}>
        <ConfirmCancelDialog
            showDialog={showDeleteAnnouncementDialog}
            dialogTitle="Löschen einer Meldung"
            dialogInfo="Soll die ausgewählte Meldung gelöscht werden?"
            onClickCancel={onAnnouncementCancel}
            onClickSubmit={onAnnouncementDeleteSubmit}
        />
        <CreateAnnouncementDialog
            showDialog={showAddSystemAnnouncementDialog}
            dialogTitle="Erstellen einer System-Meldung"
            dialogInfo="Bitte geben sie die zu erstellende Meldung an."
            onClickCancel={onAnnouncementCancel}
            onClickSubmit={onAddSystemAnnouncementSubmit}
        />
        <CreateAnnouncementDialog
            showDialog={showAddInstitutionAnnouncementDialog}
            dialogTitle="Erstellen einer Institutions-Meldung"
            dialogInfo="Bitte geben sie die zu erstellende Meldung an."
            onClickCancel={onAnnouncementCancel}
            onClickSubmit={onAddInstitutionAnnouncementSubmit}
        />
        <div className='single-view-accordion'>
            <AccordionComponent
                startIcon={<Announcement></Announcement>}
                label={"Meldungen"}
                defaultExpanded={true}
                content={<div className="flex flex-column">
                    <div>
                        {user && user.permissions.includes("announcements:edit") && <Button variant="contained" onClick={onClickAddSystemAnnouncement} sx={{
                            height: 50,
                            width: 200,
                            color: "#001D53",
                            background: "#D4E7FF",
                            marginRight: "10px",
                            marginBottom: "20px",
                            ':hover': {
                                background: 'primary.main', // theme.palette.primary.main
                                color: 'white',
                            },
                        }}>Systemmeldung Erstellen</Button>}
                        {user && user.permissions.includes("announcements_own_institution:edit") && <Button variant="contained" onClick={onClickAddInstitutionAnnouncement} sx={{
                            height: 50,
                            width: 200,
                            color: "#001D53",
                            background: "#D4E7FF",
                            marginRight: "10px",
                            marginBottom: "20px",
                            ':hover': {
                                background: 'primary.main', // theme.palette.primary.main
                                color: 'white',
                            },
                        }}>Institutionsmeldung Erstellen</Button>}
                    </div>
                    {announcements.map(announcement => {
                        return <div>
                            <div className="announcement-container">
                                <div className="announcement-container-name">
                                    {announcement.announcementMessageType === "E_ANNOUNCEMENT_TYPE_INFO" ? <Info style={{ fill: "green" }} sx={{ marginTop: "5px", marginRight: "5px", marginLeft: "5px" }}></Info> : announcement.announcementMessageType === "E_ANNOUNCEMENT_TYPE_WARNING" ? <Warning style={{ fill: "red" }} sx={{ marginTop: "5px", marginRight: "5px", marginLeft: "5px" }}></Warning> : <div></div>}
                                    {`${announcement.user.lastname},${announcement.user.firstname} [${formatDateTimeString(announcement.from)}]: ${announcement.message}`}
                                </div>
                                {((announcement.announcementType === "E_ANNOUNCEMENT_TYPE_SYSTEM" && user && user.permissions.includes("announcements:edit")) || (announcement.announcementType === "E_ANNOUNCEMENT_TYPE_INSTITUTION" && user && user.permissions.includes("announcements_own_institution:edit") && announcement.user.institution.id === user.institution.id)) &&
                                    <div className="announcement-container-fromuntil">
                                        {`[Von: ${formatDateTimeString(announcement.from)} | Bis: ${formatDateTimeString(announcement.until)}]`}
                                    </div>
                                }
                                {((announcement.announcementType === "E_ANNOUNCEMENT_TYPE_SYSTEM" && user && user.permissions.includes("announcements:edit")) || (announcement.announcementType === "E_ANNOUNCEMENT_TYPE_INSTITUTION" && user && user.permissions.includes("announcements_own_institution:edit") && announcement.user.institution.id === user.institution.id)) &&
                                    <Tooltip title={`Meldung löschen`}>
                                        <IconButton
                                            edge="end"
                                            aria-label="show"
                                            onClick={() => callOnAnnouncementDelete(announcement)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </Tooltip>
                                }
                            </div>
                            <Divider></Divider>
                        </div>
                    })}
                </div>}
            />
        </div>
        {user && <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="auto"
            sx={{ '& button': { mr: 10, mt: 2 } }}>
            <Button startIcon={<Assignment />} variant="contained" size="large" onClick={navigateToProcesses}
                sx={{
                    height: 200,
                    width: 200,
                    color: "#001D53",
                    background: "#D4E7FF",
                    ':hover': {
                        background: 'primary.main', // theme.palette.primary.main
                        color: 'white',
                    },
                }}><div>
                    <p className="home-amount-text">{`${institutionOpenProcesses.length}`}</p>
                    <p>{`offene Vorgänge`}</p>
                </div></Button>
            {user && user.permissions.includes("process:import") &&
                <Button startIcon={<Assignment />} variant="contained" size="large" onClick={navigateToProcesses}
                    sx={{
                        height: 200,
                        width: 200,
                        color: "#001D53",
                        background: "#D4E7FF",
                        ':hover': {
                            background: 'primary.main', // theme.palette.primary.main
                            color: 'white',
                        },
                    }}><div>
                        <p className="home-amount-text">{`${institutionNotImportedProcesses.length}`}</p>
                        <p>{`importierbare Vorgänge`}</p>
                    </div></Button>
            }
        </Box>}
        {user && hasGetPersonDataPermission(user.permissions, null) && <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="auto"
            sx={{ '& button': { mr: 10, mt: 2 } }}>
            <Button startIcon={<AssistWalker />} variant="contained" size="large" onClick={navigateToPersonInNeedOfCare}
                sx={{
                    height: 200,
                    width: 200,
                    color: "#001D53",
                    background: "#D4E7FF",
                    ':hover': {
                        background: 'primary.main', // theme.palette.primary.main
                        color: 'white',
                    },
                }}>Bedürftige/n suchen</Button>
            <Button startIcon={<Person />} variant="contained" size="large" onClick={navigateToCaringPerson}
                sx={{
                    height: 200,
                    width: 200,
                    color: "#001D53",
                    background: "#D4E7FF",
                    ':hover': {
                        background: 'primary.main', // theme.palette.primary.main
                        color: 'white',
                    },
                }}>Pflegeperson suchen</Button>
        </Box>
        }
    </PageLayout>
}

export default Home;