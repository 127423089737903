import { useEffect } from 'react';
import { Divider, Button } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../../../redux/store";
import { useSelector } from "react-redux";
import { } from "../../../../redux/auth.reducer";
import { StatusSelect, getStatusValues } from '../../../../models/historization';
import { InstitutionSelect } from '../../../../models/institution';
import { AddressAPIRequest, CountrySelect, getCountries } from '../../../../models/address';
import AddressForm from '../../../../shared/components/address-form/address-form';
import { GenderType, PersonAPIRequest, getGenderType } from '../../../../models/person';
import { fetchAids, fetchInsurances, fetchPensionInstitutions } from '../../../../shared/requests/institution.requests';
import PersonForm from '../../../../shared/components/person-form/person_form';
import SubmitButtonsHistory from '../../../../shared/components/standard-actions/submit-buttons/submit-button-history';
import { AssistWalker, Delete, Feed, Summarize } from '@mui/icons-material';
import CrudTable, { ColumnDefinitionType } from '../../../../shared/components/crud-table/crud-table';
import { Sort } from '../../../../hooks/useSort.hook';
import { SortingDirection } from '../../../../enums/sort-by';
import { TimeLineItemModelHistory } from '../../../../models/timeline';
import Timeline from '../../../../shared/components/time-line';
import { CalculationCaringPersonAPI, CaringPersonAPI, CaringPersonAPIRequest } from '../../../../models/caring-person';
import { createCaringPerson, deleteCaringPerson, fetchAllCalculationsForCaringPerson, fetchAllPersonConnectionForCaringPerson, fetchCaringPerson, fetchHistoryForCaringPerson, fetchSingleHistoryForCaringPerson, updateCaringPerson } from '../../../../shared/requests/caring-person.requests';
import CaringPersonForm from '../form/caring-person-form';
import ConfirmCancelDialog from '../../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog';
import { isAidUser } from '../../../../models/user';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { hasEditPersonDataPermission, hasGetPersonDataPermission, isValidPensionInsuranceNumber, isValidPostalcode } from '../../../../helper/validation';
import { PersonConnectionAPI } from '../../../../models/person-connection';
import { addCurrencySymbol, formatDateString, formatFromUntilStringMonth, formatFromUntilStringYear, formatPersonName, formatStatusString, formatTimestampString, formatTimeStampUserString, formatValidityString, getFromUntilStringFromCalculationBasisList } from '../../../../helper/formatter';
import InputErrorDialog from '../../../../shared/components/standard-actions/dialogs/input-error-dialog';
import { cancelProcess, saveProcess } from '../../../../shared/requests/user.requests';
import HistoryLine from '../../../../shared/components/history_line';
import { addToHistory, clearHistory, getHistory, HistoryTimelineItemModel, updateHistoryUntilIndex } from '../../../../models/history';
import SubmitButtonsShow from '../../../../shared/components/standard-actions/submit-buttons/submit-button-show';
import EffectiveDateForm from '../../../../shared/components/effective-date-form/effective-date-form';
import { ContractTypes, getAllContractTypes } from '../../../../models/person-in-need-of-care';
import { fetchAidConfig } from '../../../../shared/requests/aid-request';

function CaringPerson() {
    //Select Listen
    const [historyData, setHistoryData] = useState<CaringPersonAPI[]>([]);
    const [calculations, setCalculations] = useState<CalculationCaringPersonAPI[]>([]);
    const [personConnection, setPersonConnection] = useState<PersonConnectionAPI[]>([]);
    const [statusValues, setStatusValues] = useState<StatusSelect[]>([]);
    const [aidValues, setAidValues] = useState<InstitutionSelect[]>([]);
    const [insuranceValues, setInsuranceValues] = useState<InstitutionSelect[]>([]);
    const [pensionInstitutionValues, setPensionInstitutionValues] = useState<InstitutionSelect[]>([]);
    const [labelConceptOfOrderPensionInstititution, setLabelConceptOfOrderPensionInstititution] = useState<string>("");
    //Pflegeperson extra Daten
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [caringPersonExternalIdentifier, setCaringPersonExternalIdentifier] = useState<string>("");
    const [pensionInstitution, setPensionInstitution] = useState<InstitutionSelect | null>({
        id: "",
        name: "Keine Versorgungseinrichtung gewählt!",
        realValue: -1,
        type: "NoType"
    });
    const [conceptOfOrder, setConceptOfOrder] = useState<string | null>(null);
    const [insurance, setInsurance] = useState<InstitutionSelect | null>(null);
    const [aid, setAid] = useState<InstitutionSelect | null>(null);
    const [note, setNote] = useState<string | null>("");
    const [contractType, setContractType] = useState<ContractTypes | null>(null);
    //Personendaten
    const [lastname, setLastname] = useState<string>("");
    const [birthName, setBirthName] = useState<string | null>("");
    const [firstname, setFirstname] = useState<string>("");
    const [placeOfBirth, setPlaceOfBirth] = useState<string>("");
    const [countryOfBirth, setCountryOfBirth] = useState<CountrySelect | null>(null);
    const [dateOfBirth, setDateOfBirth] = useState<string | null>("");
    const [gender, setGender] = useState<GenderType | null>(null);
    const [namePrefix, setNamePrefix] = useState<string | null>("");
    const [title, setTitle] = useState<string | null>("");
    const [nationality, setNationality] = useState<CountrySelect | null>(null);
    const [pensionInsuranceNumber, setPensionInsuranceNumber] = useState<string | null>("");
    //Addressdaten
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string | null>("");
    const [country, setCountry] = useState<CountrySelect | null>(null);
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [poBox, setPOBox] = useState<string>("");
    const [poBoxPostalCode, setPOBoxPostalCode] = useState<string>("");
    const [addressSupplement, setAddressSupplement] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    //Systemdaten
    const [isShowMode, setShowMode] = useState<boolean>(false);
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showInputErrorDialog, setShowInputErrorDialog] = useState<boolean>(false);
    const [leavePath, setLeavePath] = useState<string>("/");
    const [chronoItems, setChronoItems] = useState<TimeLineItemModelHistory[]>([]);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);
    const [defaultChronoIndex, setDefaultChronoIndex] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<TimeLineItemModelHistory | null>(null);
    const [selectedHistoryIndex, setSelectedHistoryIndex] = useState<number>(-1);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const { id, history, processId } = useParams();
    const useAddress: boolean = street !== "" || (houseNumber !== null && houseNumber !== "") || (country !== null && country.id !== "") || postalCode !== "" || location !== "" || poBox !== "" || addressSupplement !== "" || phoneNumber !== "" || fax !== "" || email !== ""
    const errorCaringPerson: boolean = caringPersonExternalIdentifier === "" || contractType === null
        || insurance === null || insurance.id === "-inactive-" || aid === null || aid.id === "-inactive-" || ((pensionInstitution !== null && pensionInstitution.id !== "") && (conceptOfOrder === "" || conceptOfOrder === null)) || (pensionInstitution !== null && pensionInstitution.id === "-inactive-");
    const errorPerson: boolean = lastname === "" || firstname === "" || gender === null || ((pensionInstitution === null || pensionInstitution.id === "") && (pensionInsuranceNumber === null || pensionInsuranceNumber === "" || !isValidPensionInsuranceNumber(pensionInsuranceNumber, dateOfBirth, gender)));
    const errorAddress: boolean = useAddress && (country === null || postalCode === "" || !isValidPostalcode(postalCode, country));
    const errorInput: boolean = errorAddress || errorCaringPerson || errorPerson || effectiveDate === "";
    const navigate = useNavigate();

    const goBackToOverview = () => {
        navigate(`/caring-person`);
    }

    useEffect(() => {
        const showMode: boolean = window.location.pathname.includes("/show/")
        setShowMode(showMode)
        var today = new Date();
        if (user != null && user?.institution.type === "INSTITUTION_TYPE_AID") {
            fetchAidConfiguration(user?.institution.id)
        }
        setStatusValues(getStatusValues())
        fetchAidsWithMaybeOffset()
        fetchPensionInstituionsWithMaybeOffset()
        fetchInsurancesWithMaybeOffset()
        if (id) {
            if (!history) {
                fetchSinglePersonAPI(id, selectedItem);
            } else {
                fetchSinglePersonHistoryAPI(id, history, selectedItem);
            }
        } else {
            const historyData: HistoryTimelineItemModel[] = [];
            addToHistory({
                type: "Pflegeperson",
                name: `Neue Pflegeperson`,
                url: window.location.pathname,
                id: "-1"
            }).forEach((element => {
                historyData.push({
                    type: element.type,
                    title: element.name,
                    url: element.url,
                    id: element.id
                })
            }))
            setHistoryChronoItems(historyData);
            setEffectiveDate(today.getFullYear() + '-' + ((today.getMonth() + 1 < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + today.getDate())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, history, processId]);

    const getUserTitle = (): string => {
        const createUserTitle = "Pflegeperson erstellen";
        const editUserTitle = "Pflegeperson bearbeiten";
        const showTitle = "Pflegeperson anzeigen";
        if (isShowMode) {
            return showTitle
        }
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createEmployeeText = "Erstellen";
        const editEmployeeText = "Speichern";
        if (id) {
            return editEmployeeText;
        }
        return createEmployeeText;
    };

    const fetchSinglePersonAPI = (id: string, seleItem: TimeLineItemModelHistory | null) => {
        fetchCaringPerson(id).then((response) => {
            workWithFetchedPersonData(response.data, seleItem)
        })
    }

    const fetchSinglePersonHistoryAPI = (id: string, historyNumber: string, seleItem: TimeLineItemModelHistory | null) => {
        fetchSingleHistoryForCaringPerson(id, historyNumber).then((response) => {
            workWithFetchedPersonData(response.data, seleItem)
        });
    }

    const workWithFetchedPersonData = (person: CaringPersonAPI | CaringPersonAPI, seleItem: TimeLineItemModelHistory | null) => {
        fillCaringPersonFields(person)
        fetchAidConfiguration(person.client.id)
        fetchHistoryData(person.id, person.effectiveDate, seleItem)
        fetchPersonConnectionForCaringPerson(person.id);
        if (!isCurrentRendered) {
            const historyData: HistoryTimelineItemModel[] = [];
            addToHistory({
                type: "Pflegeperson",
                name: `${person.person.lastname}, ${person.person.firstname}`,
                url: window.location.pathname,
                id: person.id
            }).forEach((element => {
                historyData.push({
                    type: element.type,
                    title: element.name,
                    url: element.url,
                    id: element.id
                })
            }))
            setHistoryChronoItems(historyData);
            setCurrentRendered(true)
        }
    }

    const fillCaringPersonFields = (person: CaringPersonAPI | CaringPersonAPI) => {
        setEffectiveDate(person.effectiveDate);
        setCaringPersonExternalIdentifier(person.caringPersonExternalIdentifier)
        setNote(person.note)
        if (person.pensionInstitution !== null) {
            const penInst: InstitutionSelect = {
                id: person.pensionInstitution.id,
                name: person.pensionInstitution.name,
                realValue: +person.pensionInstitution.id,
                type: person.pensionInstitution.type
            }
            fetchPensionInstituionsWithMaybeOffset(penInst)
            setPensionInstitution(penInst);
        } else {
            setPensionInstitution({
                id: "",
                name: "Keine Versorgungseinrichtung gewählt!",
                realValue: -1,
                type: "NoType"
            })
        }
        setConceptOfOrder(person.conceptOfOrder);
        const elementContractType = getAllContractTypes(user, isShowMode).find((element) => { return person.contractType === element.id; });
        if (elementContractType) {
            setContractType(elementContractType)
        }
        const insur: InstitutionSelect = {
            id: person.insurance.id,
            name: person.insurance.name,
            realValue: +person.insurance.id,
            type: person.insurance.type
        }
        fetchInsurancesWithMaybeOffset(insur)
        setInsurance(insur)
        const aidd: InstitutionSelect = {
            id: person.client.id,
            name: person.client.name,
            realValue: +person.client.id,
            type: person.client.type
        }
        fetchAidsWithMaybeOffset(aidd)
        setAid(aidd)
        // Addressdaten setzen
        if (person.address != null) {
            setStreet(person.address.street);
            setHouseNumber(person.address.houseNumber ? person.address.houseNumber : "")
            const elementCountry = getCountries().find(element => element.id === person.address?.country) || null
            if (elementCountry) {
                setCountry(elementCountry);
            }
            setPostalCode(person.address.postalCode);
            setLocation(person.address.location);
            setPOBox(person.address.poBox);
            setPOBoxPostalCode(person.address.poBoxPostalCode);
            setAddressSupplement(person.address.addressSupplement);
            setPhoneNumber(person.address.phoneNumber);
            setFax(person.address.fax);
            setEmail(person.address.email);
        }

        //Personendaten setzen
        setFirstname(person.person.firstname)
        setLastname(person.person.lastname)
        setBirthName(person.person.birthName)
        setPlaceOfBirth(person.person.placeOfBirth)
        const elementCountryBirth = getCountries().find((element) => { return person.person.countryOfBirth === element.id; });
        if (elementCountryBirth) {
            setCountryOfBirth(elementCountryBirth)
        } else {
            const elementCountry = getCountries().find((element) => { return "" === element.id; });
            if (elementCountry) {
                setCountryOfBirth(elementCountry)
            }
        }
        setDateOfBirth(person.person.dateOfBirth)
        setNamePrefix(person.person.namePrefix)
        setTitle(person.person.title)
        const elementNationality = getCountries().find((element) => { return person.person.nationality === element.id; });
        if (elementNationality) {
            setNationality(elementNationality)
        } else {
            const elementCountry = getCountries().find((element) => { return "" === element.id; });
            if (elementCountry) {
                setNationality(elementCountry)
            }
        }
        setPensionInsuranceNumber(person.person.pensionInsuranceNumber)
        const elementGender = getGenderType().find((element) => { return person.person.gender === element.id; });
        if (elementGender) {
            setGender(elementGender)
        }
    }

    const fetchHistoryData = (id: string, effDate: string, seleItem: TimeLineItemModelHistory | null) => {
        fetchHistoryForCaringPerson(id).then((response2) => {
            const data: CaringPersonAPI[] = []
            const chron: TimeLineItemModelHistory[] = [];
            let index: number = 0;
            let selItem: TimeLineItemModelHistory | null = seleItem
            let selIndex: number = defaultChronoIndex;
            response2.data.filter(a => a.deleteTimestamp === null).sort((a, b) => {
                if (a.effectiveDate.toLowerCase() < b.effectiveDate.toLowerCase()) { return -1; }
                if (a.effectiveDate.toLowerCase() > b.effectiveDate.toLowerCase()) { return 1; }
                if (a.status === "STATUS_FLOATING" && b.status === "STATUS_EFFECTIVE") { return 1; }
                if (a.status === "STATUS_EFFECTIVE" && b.status === "STATUS_FLOATING") { return -1; }
                return 0;
            }).forEach(element => {
                element.deleteTimestamp = "";
                const item = {
                    title: formatDateString(element.effectiveDate),
                    validity: element.validity,
                    identifier: element.id,
                    historyNumber: element.historyNumber,
                    status: element.status,
                    effectiveDate: element.effectiveDate
                }
                chron.push(item)
                if (!history && !isCurrentRendered && element.effectiveDate === effDate && seleItem === null) {
                    selIndex = index;
                    selItem = item;
                }
                if (!isCurrentRendered && element.status === "STATUS_FLOATING" && seleItem === null) {
                    selIndex = index;
                    selItem = item;
                }
                index++;
                data.push(element);
            })
            setDefaultChronoIndex(selIndex)
            setSelectedItem(selItem)
            setHistoryData(data);
            setChronoItems(chron);
            if (chron.length < 1) {
                goBackToOverview();
            }
            fetchAllCalculationsForCaringPerson(id).then((response2) => {
                setCalculations(response2.data);
            });
        });
    }

    const fetchPensionInstituionsWithMaybeOffset = (offset?: InstitutionSelect) => {
        fetchPensionInstitutions().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            if (offset) {
                if ((institutionsSelect.find(({ id }) => String(id) === String(offset.id)) || null) === null) {
                    offset.name = "Deaktiviert: -" + offset.name + "-"
                    offset.id = "-inactive-"
                    institutionsSelect.push(offset)
                }
            }
            institutionsSelect = institutionsSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            })
            institutionsSelect.unshift({
                id: "",
                name: "Keine Versorgungseinrichtung gewählt!",
                realValue: -1,
                type: "NoType"
            })
            setPensionInstitutionValues(institutionsSelect);
        })
    }

    const fetchInsurancesWithMaybeOffset = (offset?: InstitutionSelect) => {
        fetchInsurances().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.institution.id) {
                    setInsurance({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            if (offset) {
                if ((institutionsSelect.find(({ id }) => String(id) === String(offset.id)) || null) === null) {
                    offset.name = "Deaktiviert: -" + offset.name + "-"
                    offset.id = "-inactive-"
                    institutionsSelect.push(offset)
                }
            }
            setInsuranceValues(institutionsSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }));
        })
    }

    const fetchAidsWithMaybeOffset = (offset?: InstitutionSelect) => {
        fetchAids().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.institution.id) {
                    setAid({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            if (offset) {
                if ((institutionsSelect.find(({ id }) => String(id) === String(offset.id)) || null) === null) {
                    offset.name = "Deaktiviert: -" + offset.name + "-"
                    offset.id = "-inactive-"
                    institutionsSelect.push(offset)
                }
            }
            setAidValues(institutionsSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }));
        })
    }


    const fetchPersonConnectionForCaringPerson = (caringPersonId: string) => {
        fetchAllPersonConnectionForCaringPerson(caringPersonId).then((response2) => {
            setPersonConnection(response2.data)
        });
    }

    const onTimeLineItemSelected = (index: number) => {
        const valueCasted: TimeLineItemModelHistory = chronoItems[index]
        setSelectedItem(valueCasted)
        fetchSinglePersonHistoryAPI(valueCasted.identifier, valueCasted.historyNumber, valueCasted);
    }



    const saveCaringPerson = (status: StatusSelect | null, processSave: boolean, historyClear: boolean, route?: string) => {
        if (!isShowMode) {
            let address: AddressAPIRequest | null = null;
            let coun = country ? country.id : "";
            if (useAddress) {
                address = {
                    effectiveDate,
                    houseNumber,
                    country: coun,
                    postalCode,
                    poBox,
                    poBoxPostalCode,
                    addressSupplement,
                    location,
                    phoneNumber,
                    fax,
                    email,
                    street,
                };
            } else {
                address = null;
            }

            const gen = gender ? gender.id : ""
            const nation = nationality && nationality.id !== "" ? nationality.id : null
            const count = countryOfBirth && countryOfBirth.id !== "" ? countryOfBirth.id : null;
            const dob = dateOfBirth !== "" ? dateOfBirth : null;
            const person: PersonAPIRequest = {
                lastname,
                birthName,
                firstname,
                placeOfBirth,
                countryOfBirth: count,
                dateOfBirth: dob,
                gender: gen,
                namePrefix,
                title,
                nationality: nation,
                pensionInsuranceNumber
            };

            const stat = status ? status.id : "";
            const insuranceIdentifier = insurance ? insurance.realValue : 0;
            const aidIdentifier = aid ? aid.realValue : 0;
            const penInstitutionId = pensionInstitution && pensionInstitution.id !== "" ? pensionInstitution.realValue : null;
            const processIdentifier = processId ? processId : "-1"
            const contType = contractType ? contractType.id : "";

            const caringPerson: CaringPersonAPIRequest = {
                id: id ? id : "",
                effectiveDate,
                status: stat,
                caringPersonExternalIdentifier,
                pensionInstitutionId: penInstitutionId,
                conceptOfOrder,
                insuranceIdentifier,
                aidIdentifier,
                person,
                address,
                processIdentifier,
                note,
                contractType: contType
            };
            setSavePushed(true);
            if (!errorInput && status !== null) {
                if (id) {
                    updateCaringPerson(caringPerson).then((response) => {
                        if (processSave === true && processId) {
                            saveProcess(processId).then(() => {
                                maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                            })
                        } else {
                            maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                        }
                    }).catch(console.error);
                } else {
                    createCaringPerson(caringPerson).then((response) => {
                        if (processSave === true && processId) {
                            saveProcess(processId).then(() => {
                                maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                            })
                        } else {
                            maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                        }
                    }).catch(console.error);
                }
            }
        }
    };

    const maybeClearHistoryAndNavigateRoute = (historyClear: boolean, person: CaringPersonAPI, route?: string) => {
        if (historyClear) {
            clearHistory()
        }
        const history = getHistory();
        if (history.length !== 0 && history[history.length - 1].id === "-1") {
            updateHistoryUntilIndex(history.length - 1);
            const url = processId ? `/caring-person/edit/${person.id}/${processId}/process` : `/caring-person/edit/${person.id}`
            addToHistory({
                type: "Pflegeperson",
                name: `${person.person.lastname}, ${person.person.firstname}`,
                url: url,
                id: person.id
            })
        }
        if (route) {
            navigate(route);
        }
        else {
            goBackToOverview()
        }
    }

    const onClickHistoryShow = (person: CaringPersonAPI) => {
        if (isShowMode) {
            navigate(`/caring-person/show/${id}/history/${person.historyNumber}`)
        } else {
            onClickSaveAndOpenPath(`/caring-person/edit/${id}/${processId}/process/show/${person.historyNumber}`)
        }
    }

    const onClickPersonInNeedOfCareEdit = (personConnection: PersonConnectionAPI) => {
        if (isShowMode) {
            navigate(`/person-in-need-of-care/show/${personConnection.id}`)
        } else {
            if (processId) {
                onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personConnection.personInNeedOfCare.id}/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${personConnection.personInNeedOfCare.id}`)
            }
        }
    }

    const onClickCalculationBasisEdit = (personConnection: PersonConnectionAPI) => {
        if (isShowMode) {
            navigate(`/calculation-basis/show/${personConnection.id}`)
        } else {
            if (processId) {
                onClickSaveAndOpenPath(`/calculation-basis/edit/${personConnection.id}/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/calculation-basis/edit/${personConnection.id}`)
            }
        }
    }

    const onClickCalculationBasisCreate = () => {
        if (!isShowMode) {
            if (processId) {
                onClickSaveAndOpenPath(`/calculation-basis/add/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/calculation-basis/add`)
            }
        }
    }

    const onClickPersonInNeedOfCareCreate = () => {
        if (!isShowMode) {
            if (processId) {
                onClickSaveAndOpenPath(`/person-in-need-of-care/add/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/person-in-need-of-care/add`)
            }
        }
    }

    //TODO, wenn History-ID = null beim Saven HistoryEintrag mit richtiger ID updaten
    const onClickSaveAndOpenPath = (path: string) => {
        if (!isShowMode) {
            if (!errorInput) {
                saveCaringPerson(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, false, path);
            } else {
                setLeavePath(path);
                setShowInputErrorDialog(true);
            }
        }
    }

    const onSave = () => {
        if (!isShowMode) {
            let path: string | undefined = undefined;
            let clearHistory: boolean = false;
            if (historyChronoItems.length > 1) {
                if (historyChronoItems[historyChronoItems.length - 2].type === "Pflegeperson") {
                    path = `/caring-person/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
                else if (historyChronoItems[historyChronoItems.length - 2].type === "Bedürftige/r") {
                    path = `/person-in-need-of-care/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
                else if (historyChronoItems[historyChronoItems.length - 2].type === "Berechnungsgrundlage") {
                    path = `/calculation-basis/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
            }
            saveCaringPerson(statusValues.find(({ id }) => id === "STATUS_EFFECTIVE") || null, true, clearHistory, path)
        }
    }

    const onEditCancel = () => {
        if (!isShowMode) {
            if (processId) {
                cancelProcess(processId).then(() => { goBackToOverview() });
            } else {
                goBackToOverview()
            }
        }
    }

    const actionsEdit = (
        <SubmitButtonsHistory
            processId={processId}
            submitText={getSavingButtonText()}
            cancelText="Verwerfen"
            pauseText="Unterbrechen"
            onClickSubmit={onSave}
            onClickCancel={onEditCancel}
            onClickPause={() => {
                saveCaringPerson(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, false);
            }}
        />
    );

    const onClickBack = () => {
        if (historyChronoItems.length > 1) {
            const path: string = historyChronoItems[historyChronoItems.length - 2].url
            updateHistoryUntilIndex(historyChronoItems.length - 2)
            navigate(path)
        } else {
            goBackToOverview();
        }
    }

    const actionsShow = (
        <SubmitButtonsShow
            backText="Zurück"
            onClickBack={onClickBack}
        />
    );



    const deleteEntry = () => {
        setShowDeleteDialog(true);
    }

    const onDeleteCancel = () => {
        setShowDeleteDialog(false);
    };

    const onDeleteSubmit = () => {
        if (selectedItem != null) {
            setCurrentRendered(false);
            deleteCaringPerson(selectedItem?.identifier, selectedItem?.historyNumber).then(() => {
                setSelectedItem(null)
                setDefaultChronoIndex(-1)
                fetchHistoryData(selectedItem?.identifier, effectiveDate, null);
                setShowDeleteDialog(false);
            });

        }
    };

    const setPenInsuranceNumber = (pensionNumber: string) => {
        setPensionInsuranceNumber(pensionNumber)
        if (isValidPensionInsuranceNumber(pensionNumber, dateOfBirth, gender)) {
            if (dateOfBirth === null || dateOfBirth === "") {
                const current = new Date();
                if (Number("20" + pensionNumber[6] + pensionNumber[7]) > current.getFullYear()) {
                    setDateOfBirth(`19${pensionNumber[6] + pensionNumber[7]}-${pensionNumber[4] + pensionNumber[5]}-${pensionNumber[2] + pensionNumber[3]}`)
                } else {
                    setDateOfBirth(`20${pensionNumber[6] + pensionNumber[7]}-${pensionNumber[4] + pensionNumber[5]}-${pensionNumber[2] + pensionNumber[3]}`)
                }
            }
            if (gender === null) {
                if (Number(pensionNumber[9] + pensionNumber[10]) <= 49) {
                    setGender(getGenderType()[0])
                } else {
                    setGender(getGenderType()[1])
                }

            }
        }

    }

    const onLeaveSiteCancel = () => {
        const historyData: HistoryTimelineItemModel[] = [];
        getHistory().forEach(element => {
            historyData.push({
                type: element.type,
                title: element.name,
                url: element.url,
                id: element.id
            })
        })
        setHistoryChronoItems(historyData)
        setShowInputErrorDialog(false);
    };

    const onLeaveSiteSubmit = () => {
        setShowInputErrorDialog(false);
        if (selectedHistoryIndex >= 0) {
            updateHistoryUntilIndex(selectedHistoryIndex)
        }
        navigate(leavePath);
    };

    const columnsCaringPersonHistory: ColumnDefinitionType<CaringPersonAPI, keyof CaringPersonAPI>[] = [
        {
            key: "historyNumber",
            header: "Historiennummer",
        },
        {
            key: "effectiveDate",
            header: "Wirksamkeitsdatum",
        },
        {
            key: "createTimestamp",
            header: "Zeitstempel Erstellung",
        },
        {
            key: "createDescriptionString",
            header: "Beschreibung Erstellung",
        },
        {
            key: "deleteTimestamp",
            header: "Zeitstempel Löschung",
        },
        {
            key: "deleteDescriptionString",
            header: "Beschreibung Löschung",
        },

    ];

    const columnsPersonConnection: ColumnDefinitionType<PersonConnectionAPI, keyof PersonConnectionAPI>[] = [
        {
            key: "personInNeedOfCareName",
            header: "Name, Vorname, Geburtsdatum",
        },
        {
            key: "fromUntilString",
            header: "Beziehung Von - Bis",
        },
        {
            key: "statusString",
            header: "Status",
        },
    ];

    const columnsCalculationn: ColumnDefinitionType<CalculationCaringPersonAPI, keyof CalculationCaringPersonAPI>[] = [
        {
            key: "fromUntilYearString",
            header: "Kalenderjahr",
        },
        {
            key: "fromUntilMonthString",
            header: "Monat(e)",
        },
        {
            key: "personInNeedOfCareName",
            header: "Bedürftige(r)",
        },
        {
            key: "calculationBasis",
            header: "Berechnungsbasis",
        },
        {
            key: "rvSumString",
            header: "Summe (D)RV-Beitrag",
        },
        {
            key: "avSumString",
            header: "Summe AV-Beitrag",
        },
        {
            key: "abvSumString",
            header: "Summe ABV-Beitrag",
        },
    ];

    const [sortByCaringPersonHistory] = useState<Sort<CaringPersonAPI>>({
        key: "historyNumber",
        direction: SortingDirection.Ascending,
    });

    const [sortByPersonConnection] = useState<Sort<PersonConnectionAPI>>({
        key: "personInNeedOfCareName",
        direction: SortingDirection.Descending,
    });

    const [sortByCalculationValue] = useState<Sort<CalculationCaringPersonAPI>>({
        key: "personInNeedOfCareName",
        direction: SortingDirection.Descending,
    });

    const personInNeedOfCareAllowed = (person: PersonConnectionAPI) => {
        if (user) {
            if (isShowMode) {
                return hasGetPersonDataPermission(user.permissions, person.personInNeedOfCare.contractType)
            } else {
                return hasEditPersonDataPermission(user.permissions, person.personInNeedOfCare.contractType)
            }
        } else {
            return false;
        }
    }

    const personConnectionAllowed = (person: PersonConnectionAPI) => {
        if (user) {
            if (isShowMode) {
                return hasGetPersonDataPermission(user.permissions, person.caringPerson.contractType) && hasGetPersonDataPermission(user.permissions, person.personInNeedOfCare.contractType)
            } else {
                return hasEditPersonDataPermission(user.permissions, person.caringPerson.contractType) && hasEditPersonDataPermission(user.permissions, person.personInNeedOfCare.contractType)
            }
        } else {
            return false;
        }
    }

    const setAidAndAidConfig = (value: InstitutionSelect | null) => {
        setAid(value);
        if (value !== null) {
            fetchAidConfiguration(value.id)
        }
    }

    const fetchAidConfiguration = (institutionId: string) => {
        fetchAidConfig(institutionId).then((response) => {
            setLabelConceptOfOrderPensionInstititution(response.data.labelConceptOfOrderCaringPerson)
        })
    }

    return (
        <PageLayout title={getUserTitle()} actions={isShowMode ? actionsShow : actionsEdit}>
            <ConfirmCancelDialog
                showDialog={showDeleteDialog}
                dialogTitle="Pflegeperson Eintrag löschen?"
                dialogInfo={`Möchten Sie den Eintrag mit Wirksamkeitsdatum
                    ${selectedItem?.title} wirklich löschen?`}
                onClickCancel={onDeleteCancel}
                onClickSubmit={() => onDeleteSubmit()}
            />
            <InputErrorDialog
                showDialog={showInputErrorDialog}
                dialogTitle="Bearbeitung verwerfen?"
                dialogInfo={`Die/Der Bedürftige/r kann nicht gespeichert werden, da das Formular nicht richtig ausgefüllt wurde. Soll die Seite trotzdem verlassen werden?`}
                onClickCancel={onLeaveSiteCancel}
                onClickSubmit={() => onLeaveSiteSubmit()}
            />
            <div className="single-view">
                <HistoryLine
                    items={historyChronoItems}
                    setCurrentRendered={setCurrentRendered}
                    saveAndOpenPath={isShowMode ? undefined : onClickSaveAndOpenPath}
                    errorInput={errorInput}
                    setShowInputErrorDialog={setShowInputErrorDialog}
                    setLeavePath={setLeavePath}
                    setSelectedIndex={setSelectedHistoryIndex}
                    setHistoryChronoItems={setHistoryChronoItems}
                />
                {id && <Timeline
                    items={chronoItems}
                    defaultChronoIndex={defaultChronoIndex}
                    onItemSelected={onTimeLineItemSelected}
                />}
                {(!isShowMode && id && user && isAidUser(user)) && <div className='single-view-delete-button-container'><Button variant="contained" color="primary" className="delete-entry-button" onClick={deleteEntry} sx={{ maxWidth: 1 / 6, }}>
                    <Delete sx={{ mr: 1 }}></Delete>
                    Eintrag löschen
                </Button>
                </div>}
                <EffectiveDateForm
                    savePushed={savePushed}
                    disabled={isShowMode}
                    effectiveDate={effectiveDate}
                    setEffectiveDate={setEffectiveDate}
                    formError={effectiveDate === ""}
                    validation
                />
                <PersonForm
                    savePushed={savePushed}
                    disabled={isShowMode}
                    lastname={lastname}
                    setLastname={setLastname}
                    firstname={firstname}
                    setFirstname={setFirstname}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    gender={gender}
                    setGender={setGender}
                    namePrefix={namePrefix}
                    setNamePrefix={setNamePrefix}
                    title={title}
                    setTitle={setTitle}
                    pensionInsuranceNumber={pensionInsuranceNumber}
                    setPensionInsuranceNumber={setPenInsuranceNumber}
                    formError={errorPerson}
                    pensionInsuranceNumberRequired
                    usePensionInsuranceNumber={pensionInstitution === null || pensionInstitution.id === ""}
                    validation
                />
                <AddressForm
                    savePushed={savePushed}
                    disabled={isShowMode}
                    street={street}
                    setStreet={setStreet}
                    houseNumber={houseNumber}
                    setHouseNumber={setHouseNumber}
                    postalCode={postalCode}
                    setPostalCode={setPostalCode}
                    location={location}
                    setLocation={setLocation}
                    country={country}
                    setCountry={setCountry}
                    poBox={poBox}
                    setPOBox={setPOBox}
                    poBoxPostalCode={poBoxPostalCode}
                    setPOBoxPostalCode={setPOBoxPostalCode}
                    addressSupplement={addressSupplement}
                    setAddressSupplement={setAddressSupplement}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    fax={fax}
                    setFax={setFax}
                    email={email}
                    setEmail={setEmail}
                    useAddress={useAddress}
                    formError={errorAddress}
                    validation
                />
                <CaringPersonForm
                    aid={aid}
                    aidValues={aidValues}
                    pensionInstitutionValues={pensionInstitutionValues}
                    caringPersonExternalIdentifier={caringPersonExternalIdentifier}
                    conceptOfOrder={conceptOfOrder}
                    disabled={isShowMode}
                    insurance={insurance}
                    insuranceValues={insuranceValues}
                    pensionInstitution={pensionInstitution}
                    savePushed={savePushed}
                    setAid={setAidAndAidConfig}
                    setCaringPersonExternalIdentifier={setCaringPersonExternalIdentifier}
                    setConceptOfOrder={setConceptOfOrder}
                    setInsurance={setInsurance}
                    setPensionInstitution={setPensionInstitution}
                    note={note}
                    setNote={setNote}
                    contractType={contractType}
                    setContractType={setContractType}
                    labelConceptOfOrderPensionInstitution={labelConceptOfOrderPensionInstititution}
                    user={user}
                    formError={errorCaringPerson}
                    validation
                />
                <div className='single-view-accordion'>
                    <Divider variant='fullWidth' sx={{ mt: 3, mb: 3 }} ></Divider>
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Bedürftige Beziehungen"
                            defaultExpanded={false}
                            startIcon={<AssistWalker></AssistWalker>}
                            content={
                                <CrudTable
                                    tableData={personConnection}
                                    columns={columnsPersonConnection}
                                    sortBy={sortByPersonConnection}
                                    fetchElementsWithId={fetchAllPersonConnectionForCaringPerson}
                                    changeFloatingText
                                    dataTypeName="Berechnungsgrundlage"
                                    personDataTypeName='Bedürftigen'
                                    dataFormatter={(element: PersonConnectionAPI) => {
                                        element.caringPersonName = formatPersonName(element.caringPerson.person)
                                        element.personInNeedOfCareName = formatPersonName(element.personInNeedOfCare.person)
                                        element.validityString = formatValidityString(element.validity)
                                        element.fromUntilString = getFromUntilStringFromCalculationBasisList([...element.calculationBasisListRV, ...element.calculationBasisListAV])
                                        element.statusString = formatStatusString(element.status, element.createTransaction.user)
                                        return element;
                                    }}
                                    callOnPersonConnectionClick={onClickCalculationBasisEdit}
                                    personConnectionPermission={personConnectionAllowed}
                                    callOnPersonInNeedOfCareClick={onClickPersonInNeedOfCareEdit}
                                    personInNeedOfCarePermission={personInNeedOfCareAllowed}
                                    callOnAddClick={isShowMode ? undefined : onClickCalculationBasisCreate}
                                    callOnPersonAddClick={isShowMode ? undefined : onClickPersonInNeedOfCareCreate}
                                    showGender
                                    searchingId={id}
                                />
                            }
                        />
                    </div>

                    {id &&
                        <div className='single-view-accordion'>
                            <AccordionComponent
                                label="Beitragswerte"
                                defaultExpanded={false}
                                startIcon={<Summarize></Summarize>}
                                content={
                                    <CrudTable
                                        tableData={calculations}
                                        columns={columnsCalculationn}
                                        sortBy={sortByCalculationValue}
                                        fetchElementsWithId={fetchAllCalculationsForCaringPerson}
                                        dataFormatter={(element: CalculationCaringPersonAPI) => {
                                            element.fromUntilYearString = formatFromUntilStringYear(element)
                                            element.fromUntilMonthString = formatFromUntilStringMonth(element)
                                            element.personInNeedOfCareName = formatPersonName(element.personInNeedOfCare.person)
                                            element.rvSumString = addCurrencySymbol(element.rvSum)
                                            element.avSumString = addCurrencySymbol(element.avSum)
                                            element.abvSumString = addCurrencySymbol(element.abvSum)
                                            return element;
                                        }}
                                        hideSearchbar
                                        searchingId={id}
                                    />
                                }
                            />
                        </div>
                    }
                    {id &&
                        <div className='single-view-accordion'>
                            <AccordionComponent
                                label="Historie"
                                defaultExpanded={false}
                                startIcon={<Feed></Feed>}
                                content={
                                    <CrudTable
                                        tableData={historyData}
                                        columns={columnsCaringPersonHistory}
                                        sortBy={sortByCaringPersonHistory}
                                        fetchElementsWithId={fetchHistoryForCaringPerson}
                                        callOnFindInPageClick={onClickHistoryShow}
                                        dataTypeName="Historieneintrag"
                                        dataFormatter={(element: CaringPersonAPI) => {
                                            element.createTimestamp = formatTimeStampUserString(element.createTimestamp, element.createTransaction.user)
                                            if (element.deleteTimestamp === null || element.deleteTransaction === null) {
                                                if (element.deleteTimestamp === null) {
                                                    element.deleteTimestamp = "";
                                                } else {
                                                    element.deleteTimestamp = formatTimestampString(element.deleteTimestamp)
                                                }
                                            } else {
                                                element.deleteTimestamp = formatTimeStampUserString(element.deleteTimestamp, element.deleteTransaction.user)
                                            }
                                            element.effectiveDate = formatDateString(element.effectiveDate);
                                            element.createDescriptionString = element.createTransaction.description;
                                            element.deleteDescriptionString = element.deleteTransaction ? element.deleteTransaction?.description : ""
                                            return element;
                                        }}
                                        hideSearchbar
                                        searchingId={id}
                                    />
                                }
                            />
                        </div>
                    }
                </div>
            </div>
        </PageLayout>
    );
}

export default CaringPerson;
