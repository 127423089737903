import AccordionComponent from '../../../../shared/components/accordion-component';
import { Add, Delete, Edit, Insights } from '@mui/icons-material';
import TextFieldComponent from '../../../../shared/components/text-field-component';
import { CalculationBasisAmountFlagSelect, CalculationBasisAPI, getCalculationBasisAmountFlag } from '../../../../models/calculation-basis';
import SelectComponent from '../../../../shared/components/select-component';
import { Button, Checkbox, Divider, FormControlLabel, IconButton, ListItem, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { List } from 'reactstrap';
import { formatCalculationBasisListEntry } from '../../../../helper/formatter';
import { datesOverlapping, endingDateSameOrAfterEffectiveDate } from '../../../../helper/validation';
import { useState } from 'react';
import ErrorDialog from '../../../../shared/components/standard-actions/dialogs/error-dialog';

type SelectProps = {
    disabled: boolean
    formErrorRV: boolean;
    formErrorAV: boolean;
    validation?: boolean;
    dateFromRV: string;
    setDateFromRV: (value: string) => void;
    dateUntilRV: string | null;
    setDateUntilRV: (value: string | null) => void;
    amountRV: number;
    setAmountRV: (value: number) => void;
    amountFlagRV: CalculationBasisAmountFlagSelect | null;
    setAmountFlagRV: (value: CalculationBasisAmountFlagSelect | null) => void;
    calculationBasisListRV: CalculationBasisAPI[];
    setCalculationBasisListRV: (value: CalculationBasisAPI[]) => void;
    dateFromAV: string;
    setDateFromAV: (value: string) => void;
    dateUntilAV: string | null;
    setDateUntilAV: (value: string | null) => void;
    amountAV: number;
    setAmountAV: (value: number) => void;
    amountFlagAV: CalculationBasisAmountFlagSelect | null;
    setAmountFlagAV: (value: CalculationBasisAmountFlagSelect | null) => void;
    calculationBasisListAV: CalculationBasisAPI[];
    setCalculationBasisListAV: (value: CalculationBasisAPI[]) => void;
    earliestEffectiveDateCaringPerson: string;
    earliestEffectiveDatePersonInNeedOfCare: string;
};

const CalculationBasisForm = ({
    disabled,
    formErrorRV,
    formErrorAV,
    validation,
    dateFromRV,
    setDateFromRV,
    dateUntilRV,
    setDateUntilRV,
    amountRV,
    setAmountRV,
    amountFlagRV,
    setAmountFlagRV,
    calculationBasisListRV,
    dateFromAV,
    setDateFromAV,
    dateUntilAV,
    setDateUntilAV,
    amountAV,
    setAmountAV,
    amountFlagAV,
    setAmountFlagAV,
    setCalculationBasisListRV,
    calculationBasisListAV,
    setCalculationBasisListAV,
    earliestEffectiveDateCaringPerson,
    earliestEffectiveDatePersonInNeedOfCare

}: SelectProps) => {

    const [addPushed, setAddPushed] = useState<boolean>(false);
    const [errorOverlapping, setErrorOverlapping] = useState<boolean>(false);

    const deleteCalculationBasis = (calc: CalculationBasisAPI, rv: boolean) => {
        if (!disabled) {
            const data: CalculationBasisAPI[] = []
            if (rv) {
                calculationBasisListRV.forEach((val) => {
                    if (val.id !== calc.id) {
                        data.push(val)
                    }
                })
                setCalculationBasisListRV(data.sort((a, b) => a.from > b.from ? 1 : -1))
            } else {
                calculationBasisListAV.forEach((val) => {
                    if (val.id !== calc.id) {
                        data.push(val)
                    }
                })
                setCalculationBasisListAV(data.sort((a, b) => a.from > b.from ? 1 : -1))
            }
        }
    }

    const editCalculationBasis = (calc: CalculationBasisAPI, rv: boolean) => {
        if (!disabled) {
            const data: CalculationBasisAPI[] = []
            if (rv) {
                calculationBasisListRV.forEach((val) => {
                    if (val.id !== calc.id) {
                        data.push(val)
                    }
                })
                setCalculationBasisListRV(data.sort((a, b) => a.from > b.from ? 1 : -1))
                setDateFromRV(calc.from)
                setDateUntilRV(calc.until)
                setAmountRV(calc.amount)
                setAmountFlagRV(getCalculationBasisAmountFlag().find(({ id }) => id === calc.amountFlag) || null)
            } else {
                calculationBasisListAV.forEach((val) => {
                    if (val.id !== calc.id) {
                        data.push(val)
                    }
                })
                setCalculationBasisListAV(data.sort((a, b) => a.from > b.from ? 1 : -1))
                setDateFromAV(calc.from)
                setDateUntilAV(calc.until)
                setAmountAV(calc.amount)
                setAmountFlagAV(getCalculationBasisAmountFlag().find(({ id }) => id === calc.amountFlag) || null)
            }
        }
    }

    const onAddAddToCalculationBasisList = (rv: boolean) => {
        const dateUntil = rv ? dateUntilRV : dateUntilAV;
        const dateFrom = rv ? dateFromRV : dateFromAV;
        const amountFlag = rv ? amountFlagRV : amountFlagAV;
        const amount = rv ? amountRV : amountAV;
        setAddPushed(true)
        if (((rv && !formErrorRV) || (!rv && !formErrorAV)) && endingDateSameOrAfterEffectiveDate(dateUntil, dateFrom)) {
            const data: CalculationBasisAPI[] = [];
            if (rv) {
                calculationBasisListRV.forEach(element => {
                    data.push(element);
                })
            } else {
                calculationBasisListAV.forEach(element => {
                    data.push(element);
                })
            }
            let date;
            date = new Date(dateFrom);
            const fromFormatted = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                date.getFullYear();
            let untilFormatted;
            if (dateUntil !== null) {
                date = new Date(dateUntil);
                untilFormatted = (date.getDate() < 10 ? "0" : "") + date.getDate() + "." +
                    (date.getMonth() < 9 ? "0" : "") + (date.getMonth() + 1) + "." +
                    date.getFullYear();
            } else {
                untilFormatted = "Offen";
            }


            const flag = amountFlag ? amountFlag.id : "";
            const newObject: CalculationBasisAPI = {
                id: "-1",
                amount,
                amountFlag: flag,
                from: dateFrom,
                until: dateUntil,
                fromUntilString: fromFormatted + " - " + untilFormatted,
                status: "",
                client: null
            }
            data.push(newObject);
            const changedDataList = data.map(element => {
                const newObjectEffectiveDate = Date.parse(newObject.from)
                const elementEffectiveDate = Date.parse(element.from)
                if (element.until === null && newObjectEffectiveDate !== null && elementEffectiveDate !== null && newObjectEffectiveDate > elementEffectiveDate) {
                    const date = new Date(newObject.from)
                    date.setDate(date.getDate() - 1);
                    element.until = date.toDateString()
                }
                return element;
            }).sort((a, b) => a.from > b.from ? 1 : -1);
            if (!checkCalculationBasisListForInterference(changedDataList)) {
                if (rv) {
                    setCalculationBasisListRV(changedDataList);
                    setDateFromRV("");
                    setDateUntilRV("");
                    setAmountRV(0);
                    setAmountFlagRV(null);
                } else {
                    setCalculationBasisListAV(changedDataList);
                    setDateFromAV("");
                    setDateUntilAV("");
                    setAmountAV(0);
                    setAmountFlagAV(null);
                }
                setAddPushed(false);

            } else {
                setErrorOverlapping(true)
            }
        }
    }

    const checkCalculationBasisListForInterference = (calculationBasisList: CalculationBasisAPI[]) => {
        for (let i = 0; i < calculationBasisList.length; i++) {
            const dateFromI = Date.parse(calculationBasisList[i].from);
            let dateUntilI = null;
            const yearStartI = new Date(calculationBasisList[i].from).getFullYear();
            const monthStartI = new Date(calculationBasisList[i].from).getMonth();
            let yearEndI = 0;
            let monthEndI = 0;
            if (calculationBasisList[i].until !== null) {
                dateUntilI = Date.parse(calculationBasisList[i].until!);
                yearEndI = new Date(calculationBasisList[i].until!).getFullYear();
                monthEndI = new Date(calculationBasisList[i].until!).getMonth();;
            } else {
                yearEndI = Number.MAX_VALUE;
                monthEndI = 12;
            }
            if (calculationBasisList[i].amountFlag === "AMOUNT_TYPE_YEAR") {
                if (calculationBasisList[i].until === null) {
                    return true;
                }
            }
            for (let c = i + 1; c < calculationBasisList.length; c++) {
                const dateFromC = Date.parse(calculationBasisList[c].from);
                let dateUntilC = null;
                const yearStartC = new Date(calculationBasisList[c].from).getFullYear();
                const monthStartC = new Date(calculationBasisList[c].from).getMonth();
                let yearEndC = 0;
                let monthEndC = 0;
                if (calculationBasisList[c].until !== null) {
                    dateUntilC = Date.parse(calculationBasisList[c].until!);
                    yearEndC = new Date(calculationBasisList[c].until!).getFullYear();
                    monthEndC = new Date(calculationBasisList[c].until!).getMonth();;
                } else {
                    yearEndC = Number.MAX_VALUE;
                    monthEndC = 12;
                }
                if (datesOverlapping(dateFromI, dateUntilI, dateFromC, dateUntilC)
                    && calculationBasisList[i].amountFlag === calculationBasisList[c].amountFlag) {
                    return true;
                }
                if (calculationBasisList[i].amountFlag === "EAmountType.AMOUNT_TYPE_YEAR" && (monthEndC == null
                    || monthEndI === null
                    || (monthStartI === monthStartC
                        && yearStartI === yearStartC)
                    || (monthStartI === monthEndC
                        && yearStartI === yearEndC)
                    || (monthEndI === monthStartC
                        && yearEndI === yearStartC)
                    || (monthEndI === monthEndC &&
                        yearEndI === yearEndC))) {
                    return true;
                }
            }
        }
        return false;
    }

    return <div className='single-view-accordion'>
        <ErrorDialog
            dialogInfo='Die Neue Berechnungsgrundlage würde sich mit bestehenden Berechnungsgrundlagen überschneiden und wird daher nicht hinzugefügt!'
            showDialog={errorOverlapping}
            onClickSubmit={() => setErrorOverlapping(false)}
        />
        <AccordionComponent
            defaultExpanded={false}
            label="Berechnungsgrundlagen RV"
            startIcon={<Insights></Insights>}
            error={formErrorRV}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        {!disabled && <div className="flex flex-column single-view-person__data-container">
                            <div className="flex flex-row single-view__form-row-width single-view-tripple-values">
                                <TextFieldComponent
                                    required={true}
                                    id="dateFrom"
                                    label="Datum Von"
                                    type='date'
                                    value={dateFromRV}
                                    error={(dateFromRV === "" || dateFromRV < earliestEffectiveDateCaringPerson || dateFromRV < earliestEffectiveDatePersonInNeedOfCare) && addPushed}
                                    helperText={
                                        dateFromRV === "" && addPushed
                                            ? "Das Von Datum darf nicht leer sein!"
                                            : (dateFromRV < earliestEffectiveDateCaringPerson || dateFromRV < earliestEffectiveDatePersonInNeedOfCare) && addPushed ? "Das Von Datum darf nicht vor dem frühsten Wirksamkeitsdatum einer der Personen liegen!" : ""
                                    }
                                    disabled={disabled}
                                    setValueString={setDateFromRV}
                                />
                                <TextFieldComponent
                                    required={true}
                                    id="dateUntil"
                                    label="Datum Bis"
                                    type='date'
                                    value={dateUntilRV}
                                    error={(dateUntilRV === "" || !endingDateSameOrAfterEffectiveDate(dateUntilRV, dateFromRV)) && addPushed}
                                    helperText={
                                        dateUntilRV === "" && addPushed
                                            ? "Das Bis Datum darf nicht leer sein!"
                                            : !endingDateSameOrAfterEffectiveDate(dateUntilRV, dateFromRV) && addPushed
                                                ? "Das Bis Datum darf nicht vor dem Von Datum liegen!"
                                                : ""
                                    }
                                    disabled={disabled || dateUntilRV === null}
                                    setValueString={setDateUntilRV}
                                />
                                <FormControlLabel
                                    className="grey-border"
                                    labelPlacement="end"
                                    sx={{ marginLeft: 1, marginBottom: 2 }}
                                    control={
                                        <Checkbox
                                            checked={dateUntilRV === null}
                                            onChange={() => {
                                                if (dateUntilRV == null) {
                                                    setDateUntilRV("");
                                                } else {
                                                    setDateUntilRV(null);
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label={
                                        <div className="checklist-label">
                                            <div>{"Offenes Ende"}</div>
                                        </div>
                                    } />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amount"
                                    label="Betrag in €"
                                    value={amountRV}
                                    type='number'
                                    error={amountRV < 0 && addPushed}
                                    helperText={
                                        amountRV < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountRV}
                                />
                                <SelectComponent
                                    selectData={getCalculationBasisAmountFlag()}
                                    tooltip="Es wurden noch keine Betragskennzeichen angelegt."
                                    className="single-view__text-field"
                                    label="Abrechnungstyp "
                                    value={amountFlagRV?.name || ""}
                                    onChange={(value: String) => setAmountFlagRV(getCalculationBasisAmountFlag().find(({ name }) => name === value) || null)}
                                    disabled={disabled}
                                    error={addPushed && !amountFlagRV}
                                    helperText={addPushed && !amountFlagRV ? "Es muss ein Abrechnungstyp ausgewählt werden können!" : ""}
                                ></SelectComponent>
                            </div>
                            <Button startIcon={<Add />} variant="contained" size="large" onClick={() => onAddAddToCalculationBasisList(true)}
                                sx={{
                                    height: 50,
                                    color: "#001D53",
                                    background: "#D4E7FF",
                                    ':hover': {
                                        background: 'primary.main', // theme.palette.primary.main
                                        color: 'white',
                                    },
                                }}>Berechnungsgrundlage hinzfügen</Button>

                        </div>}
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="av records"
                        >
                            {calculationBasisListRV.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.from + "-" + value.until} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={formatCalculationBasisListEntry(value)} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            {!disabled &&
                                                <Tooltip title="Berechnungsgrundlage Bearbeiten">
                                                    <IconButton edge="end" onClick={() => editCalculationBasis(value, true)}>
                                                        <Edit>

                                                        </Edit>
                                                    </IconButton >
                                                </Tooltip>}
                                            {!disabled &&
                                                <Tooltip title="Berechnungsgrundlage Löschen">
                                                    <IconButton edge="end" onClick={() => deleteCalculationBasis(value, true)}>
                                                        <Delete>

                                                        </Delete>
                                                    </IconButton >
                                                </Tooltip>}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                </div>
            }
        />
        <AccordionComponent
            defaultExpanded={false}
            label="Berechnungsgrundlagen AV"
            startIcon={<Insights></Insights>}
            error={formErrorAV}
            validation={validation}
            content={
                <div className="flex flex-row single-view-person__all-container">
                    <div className="flex flex-column single-view-person__data-container">
                        {!disabled && <div className="flex flex-column single-view-person__data-container">
                            <div className="flex flex-row single-view__form-row-width single-view-tripple-values">
                                <TextFieldComponent
                                    required={true}
                                    id="dateFrom"
                                    label="Datum Von"
                                    type='date'
                                    value={dateFromAV}
                                    error={(dateFromAV === "" || dateFromAV < earliestEffectiveDateCaringPerson || dateFromAV < earliestEffectiveDatePersonInNeedOfCare) && addPushed}
                                    helperText={
                                        dateFromAV === "" && addPushed
                                            ? "Das Von Datum darf nicht leer sein!"
                                            : (dateFromAV < earliestEffectiveDateCaringPerson || dateFromAV < earliestEffectiveDatePersonInNeedOfCare) && addPushed ? "Das Von Datum darf nicht vor dem frühsten Wirksamkeitsdatum einer der Personen liegen!" : ""
                                    }
                                    disabled={disabled}
                                    setValueString={setDateFromAV}
                                />
                                <TextFieldComponent
                                    required={true}
                                    id="dateUntil"
                                    label="Datum Bis"
                                    type='date'
                                    value={dateUntilAV}
                                    error={(dateUntilAV === "" || !endingDateSameOrAfterEffectiveDate(dateUntilAV, dateFromAV)) && addPushed}
                                    helperText={
                                        dateUntilAV === "" && addPushed
                                            ? "Das Bis Datum darf nicht leer sein!"
                                            : !endingDateSameOrAfterEffectiveDate(dateUntilAV, dateFromAV) && addPushed
                                                ? "Das Bis Datum darf nicht vor dem Von Datum liegen!"
                                                : ""
                                    }
                                    disabled={disabled || dateUntilAV === null}
                                    setValueString={setDateUntilAV}
                                />
                                <FormControlLabel
                                    className="grey-border"
                                    labelPlacement="end"
                                    sx={{ marginLeft: 1, marginBottom: 2 }}
                                    control={
                                        <Checkbox
                                            checked={dateUntilAV === null}
                                            onChange={() => {
                                                if (dateUntilAV == null) {
                                                    setDateUntilAV("");
                                                } else {
                                                    setDateUntilAV(null);
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    }
                                    label={
                                        <div className="checklist-label">
                                            <div>{"Offenes Ende"}</div>
                                        </div>
                                    } />
                            </div>
                            <div className="flex flex-row single-view__form-row-width">
                                <TextFieldComponent
                                    required={false}
                                    id="amount"
                                    label="Betrag in €"
                                    value={amountAV}
                                    type='number'
                                    error={amountAV < 0 && addPushed}
                                    helperText={
                                        amountAV < 0 && addPushed
                                            ? "Der abzurechnende Betrag darf nicht negativ sein!"
                                            : ""
                                    }
                                    disabled={disabled}
                                    setValueNumber={setAmountAV}
                                />
                                <SelectComponent
                                    selectData={getCalculationBasisAmountFlag()}
                                    tooltip="Es wurden noch keine Betragskennzeichen angelegt."
                                    className="single-view__text-field"
                                    label="Abrechnungstyp "
                                    value={amountFlagAV?.name || ""}
                                    onChange={(value: String) => setAmountFlagAV(getCalculationBasisAmountFlag().find(({ name }) => name === value) || null)}
                                    disabled={disabled}
                                    error={addPushed && !amountFlagAV}
                                    helperText={addPushed && !amountFlagAV ? "Es muss ein Abrechnungstyp ausgewählt werden können!" : ""}
                                ></SelectComponent>
                            </div>
                            <Button startIcon={<Add />} variant="contained" size="large" onClick={() => onAddAddToCalculationBasisList(false)}
                                sx={{
                                    height: 50,
                                    color: "#001D53",
                                    background: "#D4E7FF",
                                    ':hover': {
                                        background: 'primary.main', // theme.palette.primary.main
                                        color: 'white',
                                    },
                                }}>Berechnungsgrundlage hinzfügen</Button>

                        </div>}
                        <List
                            sx={{
                                width: '100%',
                                height: "400px",
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 500,
                                '& ul': { padding: 0 },
                            }}
                            aria-label="av records"
                        >
                            {calculationBasisListAV.map((value) => {
                                return <div>
                                    <ListItem disablePadding key={value.from + "-" + value.until} sx={{ background: "#EEEEEE" }}>
                                        <ListItemButton sx={{ display: "flex", justifyContent: "space-between" }}>
                                            <ListItemText primary={formatCalculationBasisListEntry(value)} sx={{
                                                color: "#2D333B",

                                            }}
                                                primaryTypographyProps={{
                                                    fontWeight: 'bold',
                                                    fontSize: 14,
                                                }} />
                                            {!disabled &&
                                                <Tooltip title="Berechnungsgrundlage Bearbeiten">
                                                    <IconButton edge="end" onClick={() => editCalculationBasis(value, false)}>
                                                        <Edit>

                                                        </Edit>
                                                    </IconButton >
                                                </Tooltip>}
                                            {!disabled &&
                                                <Tooltip title="Berechnungsgrundlage Löschen">
                                                    <IconButton edge="end" onClick={() => deleteCalculationBasis(value, false)}>
                                                        <Delete>

                                                        </Delete>
                                                    </IconButton >
                                                </Tooltip>}
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider></Divider>
                                </div>
                            })}
                        </List>
                    </div>
                </div>
            }
        />
    </div >
}
export default CalculationBasisForm;