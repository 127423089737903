import dayjs, { Dayjs } from "dayjs";
import { CountrySelect } from "../models/address";
import { InstitutionPayModelAPI } from "../models/institution";
import { GenderType, getGenderType } from "../models/person";

export const isValidPostalcode = (postalCode: string, country: CountrySelect | null) => {
    if (country != null) {
        if (country.id === "DE") {
            return postalCode.length === 5 && onlyDigits(postalCode);
        }
    }
    return true;
}


export const onlyDigits = (value: string) => {
    return value.match(/^[0-9]+$/) != null;
}

export const endingDateSameOrAfterEffectiveDate = (endingDate: string | null, effectiveDate: string) => {
    if (endingDate === null) {
        return true;
    }
    const end = Date.parse(endingDate);
    const eff = Date.parse(effectiveDate);
    if (end != null && eff != null) {
        return (end >= eff);
    }
    return false;
}

export const dateOfBirthValid = (birthDate: string) => {
    const birth = Date.parse(birthDate);
    const current = Date.now();
    const earliest = Date.parse("1870-01-01");

    if (birth != null && current != null && earliest != null) {
        return birth >= earliest && birth <= current;
    }

    return false;
}

export const dateBeforeOrSameAsCurrentDate = (date: string) => {
    const toCheck = Date.parse(date);
    const current = Date.now();
    if (toCheck != null && current != null) {
        return toCheck <= current;
    }
    return false;
}

export const dayjsDateBeforeOrSameAsCurrentDate = (date: Dayjs | null) => {
    if (date === null) {
        return false;
    }
    const toCheck = `${date.year()}-${date.month() + 1}-1`
    return dateBeforeOrSameAsCurrentDate(toCheck);
}

export const isValidPensionInsuranceNumber = (pensionInsuranceNumber: string, dateOfBirth: string | null, gender: GenderType | null) => {
    if (!pensionInsuranceNumber.match(/^[0-9]{8}[A-Z][0-9]{3}$/)) {
        return false;
    }
    const notAllowedAreaNumbers: string[] = ["01", "05", "06", "07", "22", "27", "30", "31", "32", "33", "34",
        "35", "36", "37", "41", "83", "84", "85", "86",
        "87", "88", "90", "91", "92", "93", "94", "95", "96", "97", "98", "99"];
    const areaNumber = pensionInsuranceNumber[0] + pensionInsuranceNumber[1];
    const birthDay = pensionInsuranceNumber[2] + pensionInsuranceNumber[3];
    const birthMonth = pensionInsuranceNumber[4] + pensionInsuranceNumber[5];
    const birthYear = pensionInsuranceNumber[6] + pensionInsuranceNumber[7];
    const birthName = pensionInsuranceNumber[8].toLowerCase().charCodeAt(0) - 96;
    const genderNumber = Number(pensionInsuranceNumber[9] + pensionInsuranceNumber[10]);
    const checkSum = Number(pensionInsuranceNumber[11]);
    if ((notAllowedAreaNumbers.find(element => element === areaNumber) || null) !== null) {
        return false;
    }
    if (dateOfBirth !== null) {
        if (dateOfBirth[8] + dateOfBirth[9] !== birthDay) {
            return false
        }
        if (dateOfBirth[5] + dateOfBirth[6] !== birthMonth) {
            return false
        }
        if (dateOfBirth[2] + dateOfBirth[3] !== birthYear) {
            return false
        }
    }
    if (gender !== null) {
        if (genderNumber <= 49 && gender.id !== getGenderType()[0].id) {
            return false
        }
        if (genderNumber >= 50 && gender !== getGenderType()[1]) {
            return false
        }
    }
    const onlyNumbersRVNumberWithoutCheckSum = areaNumber + birthDay + birthMonth + birthYear + (birthName < 10 ? "0" : "") + birthName + pensionInsuranceNumber[9] + pensionInsuranceNumber[10]
    if (getCheckSumForPensionInsuranceNumber(onlyNumbersRVNumberWithoutCheckSum) !== checkSum) {
        return false;
    }
    return true;
}

function getCheckSumForPensionInsuranceNumber(onlyNumbersRVNumberWithoutCheckSum: string) {
    let checkSum: number = 0;
    const multiplicationValues: number[] = [2, 1, 2, 5, 7, 1, 2, 1, 2, 1, 2, 1];
    if (onlyNumbersRVNumberWithoutCheckSum.length === multiplicationValues.length) {
        for (let i = 0; i < onlyNumbersRVNumberWithoutCheckSum.length; i++) {
            checkSum += getCrossSum(Number(onlyNumbersRVNumberWithoutCheckSum[i]) * multiplicationValues[i])
        }
    }
    return checkSum % 10;
}

function getCrossSum(value: number) {
    let sum = 0;
    while (value) {
        sum += value % 10;
        value = Math.floor(value / 10);
    }
    return sum
}

export const isValidEmail = (email: string) => {
    return email.match(/^[\w-.]+@[\w-.]+.+[\w-]$/)
}

export const payModelHasEntryFor0Calculations = (payModal: InstitutionPayModelAPI[]) => {
    let has0CalculationEntry: boolean = false;
    payModal.forEach((element) => {
        if (element.fromAmountCalculations === 0) {
            has0CalculationEntry = true;
        }
    })
    return has0CalculationEntry;
}

export const datesOverlapping = (fromA: number, untilA: number | null, fromB: number, untilB: number | null) => {
    if (untilA === null) {
        return untilB === null || fromB >= fromA || untilB >= fromA;
    }
    if (untilB === null) {
        return fromA >= fromB || untilA >= fromB;
    }
    if (fromB < fromA || fromB > untilA) {
        return fromB < fromA && untilB >= fromA;
    } else {
        return true;
    }
}

export const hasEditPersonDataPermission = (permission: string[] | undefined, contractType: string | null) => {
    if (!permission) {
        return false;
    }
    if (permission.includes("data_normal:edit") && (contractType == null || contractType === "E_CONTRACT_TYPE_NORMAL")) {
        return true;
    }
    if (permission.includes("data_employee:edit") && (contractType == null || contractType === "E_CONTRACT_TYPE_EMPLOYEE")) {
        return true;
    }
    if (permission.includes("data_vip:edit") && (contractType == null || contractType === "E_CONTRACT_TYPE_VIP")) {
        return true;
    }
    return false;
}

export const hasGetPersonDataPermission = (permission: string[] | undefined, contractType: string | null) => {
    if (!permission) {
        return false;
    }
    if (permission.includes("data_normal:read") && (contractType == null || contractType === "E_CONTRACT_TYPE_NORMAL")) {
        return true;
    }
    if (permission.includes("data_employee:read") && (contractType == null || contractType === "E_CONTRACT_TYPE_EMPLOYEE")) {
        return true;
    }
    if (permission.includes("data_vip:read") && (contractType == null || contractType === "E_CONTRACT_TYPE_VIP")) {
        return true;
    }
    return false;
}

export const timestampStringBeforeCurrentDate = (dateTimeString: string) => {
    var date = dayjs(dateTimeString, "MM-DD-YYYY");
    var dateNow = dayjs()
    return date < dateNow;
}
