import { useEffect } from 'react';
import { Button, Divider } from "@mui/material";
import PageLayout from "../../../../shared/components/page-layout";
import "../../details.css";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    ContractTypes,
    getAllContractTypes,
    PersonInNeedOfCareAPI,
    PersonInNeedOfCareAPIRequest,
} from "../../../../models/person-in-need-of-care";
import {
    createPersonInNeedOfCare,
    deletePersonInNeedOfCare,
    fetchAllPersonConnectionForPersonInNeedOfCare,
    fetchHistoryForPersonInNeedOfCare,
    fetchPersonInNeedOfCare,
    fetchSingleHistoryForPersonInNeedOfCare,
    updatePersonInNeedOfCare,
} from "../../../../shared/requests/person-in-need-of-care.requests";
import { StatusSelect, getStatusValues } from '../../../../models/historization';
import { InstitutionSelect } from '../../../../models/institution';
import { AddressAPIRequest, CountrySelect, getCountries } from '../../../../models/address';
import AddressForm from '../../../../shared/components/address-form/address-form';
import { GenderType, PersonAPIRequest, getGenderType } from '../../../../models/person';
import { fetchAids, fetchInsurances } from '../../../../shared/requests/institution.requests';
import PersonForm from '../../../../shared/components/person-form/person_form';
import SubmitButtonsHistory from '../../../../shared/components/standard-actions/submit-buttons/submit-button-history';
import { Delete, Feed, Person } from '@mui/icons-material';
import CrudTable, { ColumnDefinitionType } from '../../../../shared/components/crud-table/crud-table';
import { Sort } from '../../../../hooks/useSort.hook';
import { SortingDirection } from '../../../../enums/sort-by';
import { TimeLineItemModelHistory } from '../../../../models/timeline';
import Timeline from '../../../../shared/components/time-line';
import PersonInNeedOfCareForm from '../form/person-in-need-of-care-form';
import ConfirmCancelDialog from '../../../../shared/components/standard-actions/dialogs/confirm-cancel-dialog';
import { isAidUser } from '../../../../models/user';
import AccordionComponent from '../../../../shared/components/accordion-component';
import { hasEditPersonDataPermission, hasGetPersonDataPermission, isValidPostalcode } from '../../../../helper/validation';
import { fetchAidConfig } from '../../../../shared/requests/aid-request';
import { AidConfigAidLevels } from '../../../../models/aid';
import { PersonConnectionAPI } from '../../../../models/person-connection';
import { formatDateString, formatPersonName, formatStatusString, formatTimestampString, formatTimeStampUserString, formatValidityString, getFromUntilStringFromCalculationBasisList } from '../../../../helper/formatter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import InputErrorDialog from '../../../../shared/components/standard-actions/dialogs/input-error-dialog';
import { cancelProcess, saveProcess } from '../../../../shared/requests/user.requests';
import { addToHistory, clearHistory, getHistory, HistoryTimelineItemModel, updateHistoryUntilIndex } from '../../../../models/history';
import HistoryLine from '../../../../shared/components/history_line';
import SubmitButtonsShow from '../../../../shared/components/standard-actions/submit-buttons/submit-button-show';
import EffectiveDateForm from '../../../../shared/components/effective-date-form/effective-date-form';

function PersonInNeedOfCare() {
    //Select Listen
    const [historyData, setHistoryData] = useState<PersonInNeedOfCareAPI[]>([]);
    const [personConnection, setPersonConnection] = useState<PersonConnectionAPI[]>([]);
    const [statusValues, setStatusValues] = useState<StatusSelect[]>([]);
    const [aidValues, setAidValues] = useState<InstitutionSelect[]>([]);
    const [insuranceValues, setInsuranceValues] = useState<InstitutionSelect[]>([]);
    const [aidLevelValues, setAidLevelValues] = useState<AidConfigAidLevels[]>([]);
    const [useSpecialConceptOfOrderPersonInNeedOfCare, setUseSpecialConceptOfOrderPersonInNeedOfCare] = useState<boolean>(false);
    const [labelConceptOfOrderOnePersonInNeedOfCare, setLabelConceptOfOrderOnePersonInNeedOfCare] = useState<string>("");
    const [labelConceptOfOrderTwoPersonInNeedOfCare, setLabelConceptOfOrderTwoPersonInNeedOfCare] = useState<string>("");
    const [labelExternalConceptOfOrderOnePersonInNeedOfCare, setLabelExternalConceptOfOrderOnePersonInNeedOfCare] = useState<string>("");
    const [labelExternalConceptOfOrderTwoPersonInNeedOfCare, setLabelExternalConceptOfOrderTwoPersonInNeedOfCare] = useState<string>("");
    const [labelSpecialConceptOfOrderOnePersonInNeedOfCare, setLabelSpecialConceptOfOrderOnePersonInNeedOfCare] = useState<string>("");
    const [labelSpecialConceptOfOrderTwoPersonInNeedOfCare, setLabelSpecialConceptOfOrderTwoPersonInNeedOfCare] = useState<string>("");
    //Bedürftige extra Daten
    const [effectiveDate, setEffectiveDate] = useState<string>("");
    const [conceptOfOrder1, setConceptOfOrder1] = useState<string>("");
    const [conceptOfOrder2, setConceptOfOrder2] = useState<string>("");
    const [externalConceptOfOrder1, setExternalConceptOfOrder1] = useState<string>("");
    const [externalConceptOfOrder2, setExternalConceptOfOrder2] = useState<string>("");
    const [specialConceptOfOrder1, setSpecialConceptOfOrder1] = useState<string>("");
    const [specialConceptOfOrder2, setSpecialConceptOfOrder2] = useState<string>("");
    const [aidLevel, setAidLevel] = useState<AidConfigAidLevels | null>(null);
    const [contractType, setContractType] = useState<ContractTypes | null>(null);
    const [insurance, setInsurance] = useState<InstitutionSelect | null>(null);
    const [aid, setAid] = useState<InstitutionSelect | null>(null);
    const [note, setNote] = useState<string | null>("");
    //Personendaten
    const [lastname, setLastname] = useState<string>("");
    const [birthName, setBirthName] = useState<string | null>("");
    const [firstname, setFirstname] = useState<string>("");
    const [placeOfBirth, setPlaceOfBirth] = useState<string>("");
    const [countryOfBirth, setCountryOfBirth] = useState<CountrySelect | null>(null);
    const [dateOfBirth, setDateOfBirth] = useState<string | null>("");
    const [gender, setGender] = useState<GenderType | null>(null);
    const [namePrefix, setNamePrefix] = useState<string | null>("");
    const [title, setTitle] = useState<string | null>("");
    const [nationality, setNationality] = useState<CountrySelect | null>(null);
    const [pensionInsuranceNumber, setPensionInsuranceNumber] = useState<string | null>("");
    //Addressdaten
    const [street, setStreet] = useState<string>("");
    const [houseNumber, setHouseNumber] = useState<string | null>("");
    const [country, setCountry] = useState<CountrySelect | null>(null);
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [poBox, setPOBox] = useState<string>("");
    const [poBoxPostalCode, setPOBoxPostalCode] = useState<string>("");
    const [addressSupplement, setAddressSupplement] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [fax, setFax] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    //Systemdaten
    const [isShowMode, setShowMode] = useState<boolean>(false);
    const [savePushed, setSavePushed] = useState<boolean>(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [showInputErrorDialog, setShowInputErrorDialog] = useState<boolean>(false);
    const [leavePath, setLeavePath] = useState<string>("/");
    const [chronoItems, setChronoItems] = useState<TimeLineItemModelHistory[]>([]);
    const [historyChronoItems, setHistoryChronoItems] = useState<HistoryTimelineItemModel[]>([]);
    const [defaultChronoIndex, setDefaultChronoIndex] = useState<number>(0);
    const [selectedItem, setSelectedItem] = useState<TimeLineItemModelHistory | null>(null);
    const [isCurrentRendered, setCurrentRendered] = useState<boolean>(false);
    const [selectedHistoryIndex, setSelectedHistoryIndex] = useState<number>(-1);
    const { user } = useSelector(({ authReducer }: RootState) => authReducer);
    const { id, history, processId } = useParams();
    const useAddress: boolean = street !== "" || (houseNumber != null && houseNumber !== "") || (country !== null && country.id !== "") || postalCode !== "" || location !== "" || poBox !== "" || addressSupplement !== "" || phoneNumber !== "" || fax !== "" || email !== ""
    const errorPersonInNeedOfCare: boolean = conceptOfOrder1 === "" || aidLevel === null || contractType === null || insurance === null || aid === null || insurance.id === "-inactive-" || aid.id === "-inactive-";
    const errorPerson: boolean = lastname === "" || firstname === "" || gender === null;
    const errorAddress: boolean = useAddress && (country === null || postalCode === "" || !isValidPostalcode(postalCode, country));
    const errorInput: boolean = errorAddress || errorPersonInNeedOfCare || errorPerson || effectiveDate === "";

    const navigate = useNavigate();

    const goBackToOverview = () => {
        navigate(`/person-in-need-of-care`);
    }

    useEffect(() => {
        const showMode: boolean = window.location.pathname.includes("/show/")
        setShowMode(showMode)
        var today = new Date();
        setStatusValues(getStatusValues())
        if (user != null && user?.institution.type === "INSTITUTION_TYPE_AID") {
            fetchAidConfiguration(user?.institution.id)
        }
        fetchAidsWithMaybeOffset()
        fetchInsurancesWithMaybeOffset();
        if (id) {
            if (!history) {
                fetchSinglePersonAPI(id, selectedItem);
            } else {
                fetchSinglePersonHistoryAPI(id, history, selectedItem);
            }
        } else {
            const historyData: HistoryTimelineItemModel[] = [];
            addToHistory({
                type: "Bedürftige/r",
                name: `Neue/r Bedürftige/r`,
                url: window.location.pathname,
                id: "-1"
            }).forEach((element => {
                historyData.push({
                    type: element.type,
                    title: element.name,
                    url: element.url,
                    id: element.id
                })
            }))
            setHistoryChronoItems(historyData);
            setEffectiveDate(today.getFullYear() + '-' + ((today.getMonth() + 1 < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + '-' + today.getDate())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, history, processId]);

    const getUserTitle = (): string => {
        const createUserTitle = "Bedürftige/n erstellen";
        const editUserTitle = "Bedürftige/n bearbeiten";
        const showTitle = "Bedürftige/n anzeigen";
        if (isShowMode) {
            return showTitle
        }
        if (id) {
            return editUserTitle;
        }
        return createUserTitle;
    };

    const getSavingButtonText = (): string => {
        const createEmployeeText = "Erstellen";
        const editEmployeeText = "Speichern";
        if (id) {
            return editEmployeeText;
        }
        return createEmployeeText;
    };

    const setAidAndAidLevels = (value: InstitutionSelect | null) => {
        setAid(value);
        if (value !== null) {
            fetchAidConfiguration(value.id)
        }
    }

    const fetchSinglePersonAPI = (id: string, seleItem: TimeLineItemModelHistory | null) => {
        fetchPersonInNeedOfCare(id).then((response) => {
            workWithFetchedPersonData(response.data, seleItem);
        });

    }


    const fetchSinglePersonHistoryAPI = (id: string, historyNumber: string, seleItem: TimeLineItemModelHistory | null) => {
        fetchSingleHistoryForPersonInNeedOfCare(id, historyNumber).then((response) => {
            workWithFetchedPersonData(response.data, seleItem);
        });
    }

    const workWithFetchedPersonData = (person: PersonInNeedOfCareAPI | PersonInNeedOfCareAPI, seleItem: TimeLineItemModelHistory | null) => {
        fillPersonInNeedOfCareFields(person)
        fetchHistoryData(person.id, person.effectiveDate, seleItem)
        fetchAidConfiguration(person.client.id)
        fetchPersonConnectionForCaringPerson(person.id)
        if (!isCurrentRendered) {
            const historyData: HistoryTimelineItemModel[] = [];
            addToHistory({
                type: "Bedürftige/r",
                name: `${person.person.lastname}, ${person.person.firstname}`,
                url: window.location.pathname,
                id: person.id
            }).forEach((element => {
                historyData.push({
                    type: element.type,
                    title: element.name,
                    url: element.url,
                    id: element.id
                })
            }))
            setHistoryChronoItems(historyData);
            setCurrentRendered(true);
        }
    }

    const fillPersonInNeedOfCareFields = (person: PersonInNeedOfCareAPI | PersonInNeedOfCareAPI) => {
        setEffectiveDate(person.effectiveDate);
        setConceptOfOrder1(person.conceptOfOrder1);
        setConceptOfOrder2(person.conceptOfOrder2 ? person.conceptOfOrder2 : "");
        setExternalConceptOfOrder1(person.externalConceptOfOrder1 ? person.externalConceptOfOrder1 : "");
        setExternalConceptOfOrder2(person.externalConceptOfOrder2 ? person.externalConceptOfOrder2 : "");
        setSpecialConceptOfOrder1(person.specialConceptOfOrder1 ? person.specialConceptOfOrder1 : "");
        setSpecialConceptOfOrder2(person.specialConceptOfOrder2 ? person.specialConceptOfOrder2 : "");
        setNote(person.note)
        if (person.aidLevel) {
            setAidLevel({
                id: String(person.aidLevel.id),
                name: String(person.aidLevel.percentage) + "%"
            });
        } else {
            setAidLevel(null);
        }
        const elementContractType = getAllContractTypes(user, isShowMode).find((element) => { return person.contractType === element.id; });
        if (elementContractType) {
            setContractType(elementContractType)
        }
        const insur: InstitutionSelect = {
            id: person.insurance.id,
            name: person.insurance.name,
            realValue: +person.insurance.id,
            type: person.insurance.type
        }
        setInsurance(insur)
        fetchInsurancesWithMaybeOffset(insur)
        const aidd: InstitutionSelect = {
            id: person.client.id,
            name: person.client.name,
            realValue: +person.client.id,
            type: person.client.type
        }
        setAid(aidd)
        fetchAidsWithMaybeOffset(aidd)
        // Addressdaten setzen
        if (person.address != null) {
            setStreet(person.address.street);
            setHouseNumber(person.address.houseNumber);
            const elementCountry = getCountries().find(element => element.id === person.address?.country) || null
            if (elementCountry) {
                setCountry(elementCountry);
            }
            setPostalCode(person.address.postalCode);
            setLocation(person.address.location);
            setPOBox(person.address.poBox);
            setPOBoxPostalCode(person.address.poBoxPostalCode);
            setAddressSupplement(person.address.addressSupplement);
            setPhoneNumber(person.address.phoneNumber);
            setFax(person.address.fax);
            setEmail(person.address.email);
        }
        //Personendaten setzen
        setFirstname(person.person.firstname)
        setLastname(person.person.lastname)
        setBirthName(person.person.birthName)
        setPlaceOfBirth(person.person.placeOfBirth)
        const elementCountryBirth = getCountries().find((element) => { return person.person.countryOfBirth === element.id; });
        if (elementCountryBirth) {
            setCountryOfBirth(elementCountryBirth)
        } else {
            const elementCountry = getCountries().find((element) => { return "" === element.id; });
            if (elementCountry) {
                setCountryOfBirth(elementCountry)
            }
        }
        setDateOfBirth(person.person.dateOfBirth)
        setNamePrefix(person.person.namePrefix)
        setTitle(person.person.title)
        const elementNationality = getCountries().find((element) => { return person.person.nationality === element.id; });
        if (elementNationality) {
            setNationality(elementNationality)
        } else {
            const elementCountry = getCountries().find((element) => { return "" === element.id; });
            if (elementCountry) {
                setNationality(elementCountry)
            }
        }
        setPensionInsuranceNumber(person.person.pensionInsuranceNumber)
        const elementGender = getGenderType().find((element) => { return person.person.gender === element.id; });
        if (elementGender) {
            setGender(elementGender)
        }
    }


    const fetchHistoryData = (id: string, effDate: string, seleItem: TimeLineItemModelHistory | null) => {
        fetchHistoryForPersonInNeedOfCare(id).then((response2) => {
            const data: PersonInNeedOfCareAPI[] = []
            const chron: TimeLineItemModelHistory[] = [];
            let index: number = 0;
            let selItem: TimeLineItemModelHistory | null = seleItem
            let selIndex: number = defaultChronoIndex;
            response2.data.forEach(element => {
                if (element.deleteTimestamp === null) {
                    element.deleteTimestamp = "";
                }
                if (element.deleteTransaction === null) {
                    const item = {
                        title: formatDateString(element.effectiveDate),
                        validity: element.validity,
                        identifier: element.id,
                        historyNumber: element.historyNumber,
                        status: element.status,
                        effectiveDate: element.effectiveDate
                    }
                    chron.push(item)
                    if (!history && !isCurrentRendered && element.effectiveDate === effDate && seleItem === null) {
                        selIndex = index;
                        selItem = item;
                    }
                    if (!isCurrentRendered && element.status === "STATUS_FLOATING" && seleItem === null) {
                        selIndex = index;
                        selItem = item;
                    }
                    index++;
                }
                data.push(element);
            })
            setDefaultChronoIndex(selIndex)
            setSelectedItem(selItem)
            setHistoryData(data);
            setChronoItems(chron.sort((a, b) => {
                if (a.effectiveDate.toLowerCase() < b.effectiveDate.toLowerCase()) { return -1; }
                if (a.effectiveDate.toLowerCase() > b.effectiveDate.toLowerCase()) { return 1; }
                if (a.status === "STATUS_FLOATING" && b.status === "STATUS_EFFECTIVE") { return 1; }
                if (a.status === "STATUS_EFFECTIVE" && b.status === "STATUS_FLOATING") { return -1; }
                return 0;
            }));
            if (chron.length < 1) {
                goBackToOverview();
            }
        });
    }

    const fetchInsurancesWithMaybeOffset = (offset?: InstitutionSelect) => {
        fetchInsurances().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.institution.id) {
                    setInsurance({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            if (offset) {
                if ((institutionsSelect.find(({ id }) => String(id) === String(offset.id)) || null) === null) {
                    offset.name = "Deaktiviert: -" + offset.name + "-"
                    offset.id = "-inactive-"
                    institutionsSelect.push(offset)
                }
            }
            setInsuranceValues(institutionsSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }));
        })
    }

    const fetchAidsWithMaybeOffset = (offset?: InstitutionSelect) => {
        fetchAids().then((response) => {
            let institutions = response.data;
            let institutionsSelect: InstitutionSelect[] = [];
            institutions.forEach((institution) => {
                if (institution.id === user?.institution.id) {
                    setAid({
                        id: String(institution.id),
                        name: institution.name,
                        realValue: +institution.id,
                        type: institution.type
                    });
                }
                institutionsSelect.push({
                    id: String(institution.id),
                    name: institution.name,
                    realValue: +institution.id,
                    type: institution.type
                })
            })
            if (offset) {
                if ((institutionsSelect.find(({ id }) => String(id) === String(offset.id)) || null) === null) {
                    offset.name = "Deaktiviert: -" + offset.name + "-"
                    offset.id = "-inactive-"
                    institutionsSelect.push(offset)
                }
            }
            setAidValues(institutionsSelect.sort((a, b) => {
                if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                return 0;
            }));
        })
    }

    const fetchAidConfiguration = (institutionId: string) => {
        fetchAidConfig(institutionId).then((response) => {
            const aidLevelVal: AidConfigAidLevels[] = []
            response.data.aidLevels.sort((a, b) => a > b ? 1 : -1).forEach((element) => {
                if (element.validity === "VALIDITY_VALID") {
                    aidLevelVal.push({
                        id: element.id,
                        name: String(element.percentage) + "%"
                    })
                }
            })
            setAidLevelValues(aidLevelVal);
            setUseSpecialConceptOfOrderPersonInNeedOfCare(response.data.useSpecialConceptOfOrderPersonInNeedOfCare)
            setLabelConceptOfOrderOnePersonInNeedOfCare(response.data.labelConceptOfOrderOnePersonInNeedOfCare)
            setLabelConceptOfOrderTwoPersonInNeedOfCare(response.data.labelConceptOfOrderTwoPersonInNeedOfCare)
            setLabelExternalConceptOfOrderOnePersonInNeedOfCare(response.data.labelExternalConceptOfOrderOnePersonInNeedOfCare)
            setLabelExternalConceptOfOrderTwoPersonInNeedOfCare(response.data.labelExternalConceptOfOrderTwoPersonInNeedOfCare)
            setLabelSpecialConceptOfOrderOnePersonInNeedOfCare(response.data.labelSpecialConceptOfOrderOnePersonInNeedOfCare)
            setLabelSpecialConceptOfOrderTwoPersonInNeedOfCare(response.data.labelSpecialConceptOfOrderTwoPersonInNeedOfCare)
        })
    }

    const fetchPersonConnectionForCaringPerson = (personInNeedOfCareId: string) => {
        fetchAllPersonConnectionForPersonInNeedOfCare(personInNeedOfCareId).then((response2) => {
            setPersonConnection(response2.data)
        });
    }

    const onTimeLineItemSelected = (index: number) => {
        const valueCasted: TimeLineItemModelHistory = chronoItems[index]
        setSelectedItem(valueCasted)
        fetchSinglePersonHistoryAPI(valueCasted.identifier, valueCasted.historyNumber, valueCasted)

    }



    const savePersonInNeedOfCare = (status: StatusSelect | null, processSave: boolean, historyClear: boolean, route?: string) => {
        if (!isShowMode) {
            let address: AddressAPIRequest | null = null;
            let coun = country ? country.id : "";
            if (useAddress) {
                address = {
                    effectiveDate,
                    houseNumber,
                    country: coun,
                    postalCode,
                    poBox,
                    poBoxPostalCode,
                    addressSupplement,
                    location,
                    phoneNumber,
                    fax,
                    email,
                    street,
                };
            } else {
                address = null;
            }

            const gen = gender ? gender.id : ""
            const nation = nationality && nationality.id !== "" ? nationality.id : null
            const count = countryOfBirth && countryOfBirth.id !== "" ? countryOfBirth.id : null;
            const dob = dateOfBirth !== "" ? dateOfBirth : null;
            const person: PersonAPIRequest = {
                lastname,
                birthName,
                firstname,
                placeOfBirth,
                countryOfBirth: count,
                dateOfBirth: dob,
                gender: gen,
                namePrefix,
                title,
                nationality: nation,
                pensionInsuranceNumber
            };

            const stat = status ? status.id : "";
            const insuranceIdentifier = insurance ? insurance.realValue : 0;
            const aidIdentifier = aid ? aid.realValue : 0;
            const contType = contractType ? contractType.id : "";
            const conc2 = conceptOfOrder2 === "" ? conceptOfOrder2 : null;
            const extConc1 = externalConceptOfOrder1 === "" ? externalConceptOfOrder1 : null;
            const extConc2 = externalConceptOfOrder2 === "" ? externalConceptOfOrder2 : null;
            const spectConc1 = specialConceptOfOrder1 === "" ? specialConceptOfOrder1 : null;
            const spectConc2 = specialConceptOfOrder2 === "" ? specialConceptOfOrder2 : null;
            const aLevel = aidLevel ? Number(aidLevel.id) : 0
            const processIdentifier = processId ? processId : "-1"
            const personInNeedOfCare: PersonInNeedOfCareAPIRequest = {
                id: id ? id : "",
                effectiveDate,
                status: stat,
                conceptOfOrder1,
                conceptOfOrder2: conc2,
                externalConceptOfOrder1: extConc1,
                externalConceptOfOrder2: extConc2,
                specialConceptOfOrder1: spectConc1,
                specialConceptOfOrder2: spectConc2,
                aidLevelIdentifier: aLevel,
                contractType: contType,
                insuranceIdentifier,
                aidIdentifier,
                person,
                address,
                processIdentifier,
                note
            };
            setSavePushed(true);
            if (!errorInput && status !== null) {
                if (id) {
                    updatePersonInNeedOfCare(personInNeedOfCare).then((response) => {
                        if (processSave === true && processId) {
                            saveProcess(processId).then(() => {
                                maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                            })
                        } else {
                            maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                        }
                    }).catch(console.error);
                } else {
                    createPersonInNeedOfCare(personInNeedOfCare).then((response) => {
                        if (processSave === true && processId) {
                            saveProcess(processId).then(() => { maybeClearHistoryAndNavigateRoute(historyClear, response.data, route) })
                        } else {
                            maybeClearHistoryAndNavigateRoute(historyClear, response.data, route)
                        }

                    }).catch(console.error);
                }
            }
        }
    };

    const maybeClearHistoryAndNavigateRoute = (historyClear: boolean, person: PersonInNeedOfCareAPI, route?: string) => {
        if (historyClear) {
            clearHistory()
        }
        const history = getHistory();
        if (history.length !== 0 && history[history.length - 1].id === "-1") {
            updateHistoryUntilIndex(history.length - 1);
            const url = processId ? `/person-in-need-of-care/edit/${person.id}/${processId}/process` : `/person-in-need-of-care/edit/${person.id}`
            addToHistory({
                type: "Bedürftige/r",
                name: `${person.person.lastname}, ${person.person.firstname}`,
                url: url,
                id: person.id
            })
        }
        if (route) {
            navigate(route);
        }
        else {
            goBackToOverview()
        }
    }

    const onClickHistoryShow = (person: PersonInNeedOfCareAPI) => {
        if (isShowMode) {
            navigate(`/person-in-need-of-care/show/${id}/history/${person.historyNumber}`)
        } else {
            onClickSaveAndOpenPath(`/person-in-need-of-care/edit/${id}/${processId}/process/show/${person.historyNumber}`)
        }
    }

    const onClickCaringPersonEdit = (personConnection: PersonConnectionAPI) => {
        if (isShowMode) {
            navigate(`/caring-person/show/${personConnection.caringPerson.id}`)
        } else {
            if (processId) {
                onClickSaveAndOpenPath(`/caring-person/edit/${personConnection.caringPerson.id}/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/caring-person/edit/${personConnection.caringPerson.id}`)
            }
        }
    }

    const onClickCalculationBasisEdit = (personConnection: PersonConnectionAPI) => {
        if (isShowMode) {
            navigate(`/calculation-basis/show/${personConnection.id}`)
        } else {
            if (processId) {
                onClickSaveAndOpenPath(`/calculation-basis/edit/${personConnection.id}/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/calculation-basis/edit/${personConnection.id}`)
            }
        }
    }

    const onClickCalculationBasisCreate = () => {
        if (!isShowMode) {
            if (processId) {
                onClickSaveAndOpenPath(`/calculation-basis/add/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/calculation-basis/add`)
            }
        }
    }

    const onClickCaringPersonCreate = () => {
        if (!isShowMode) {
            if (processId) {
                onClickSaveAndOpenPath(`/caring-person/add/${processId}`)
            } else {
                onClickSaveAndOpenPath(`/caring-person/add`)
            }
        }
    }

    const onClickSaveAndOpenPath = (path: string) => {
        if (!isShowMode) {
            if (!errorInput) {
                savePersonInNeedOfCare(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, false, path);
            } else {
                setHistoryChronoItems([])
                setLeavePath(path);
                setShowInputErrorDialog(true);
            }
        }
    }

    const onSave = () => {
        if (!isShowMode) {
            let path: string | undefined = undefined;
            let clearHistory: boolean = false;
            if (historyChronoItems.length > 1) {
                if (historyChronoItems[historyChronoItems.length - 2].type === "Pflegeperson") {
                    path = `/caring-person/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
                else if (historyChronoItems[historyChronoItems.length - 2].type === "Bedürftige/r") {
                    path = `/person-in-need-of-care/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
                else if (historyChronoItems[historyChronoItems.length - 2].type === "Berechnungsgrundlage") {
                    path = `/calculation-basis/edit/${historyChronoItems[historyChronoItems.length - 2].id}`
                    clearHistory = true;
                }
            }
            savePersonInNeedOfCare(statusValues.find(({ id }) => id === "STATUS_EFFECTIVE") || null, true, clearHistory, path)
        }
    }

    const onEditCancel = () => {
        if (!isShowMode) {
            if (processId) {
                cancelProcess(processId).then(() => { goBackToOverview() });
            } else {
                goBackToOverview()
            }
        }
    }


    const actions = (
        <SubmitButtonsHistory
            processId={processId}
            submitText={getSavingButtonText()}
            cancelText="Verwerfen"
            pauseText="Unterbrechen"
            onClickSubmit={onSave}
            onClickCancel={onEditCancel}
            onClickPause={() => {
                savePersonInNeedOfCare(statusValues.find(({ id }) => id === "STATUS_FLOATING") || null, false, false);
            }}
        />
    );

    const onClickBack = () => {
        if (historyChronoItems.length > 1) {
            const path: string = historyChronoItems[historyChronoItems.length - 2].url
            updateHistoryUntilIndex(historyChronoItems.length - 2)
            navigate(path)
        } else {
            goBackToOverview();
        }
    }

    const actionsShow = (
        <SubmitButtonsShow
            backText="Zurück"
            onClickBack={onClickBack}
        />
    );

    const deleteEntry = () => {
        setShowDeleteDialog(true);
    }

    const onDeleteCancel = () => {
        setShowDeleteDialog(false);
    };

    const onDeleteSubmit = () => {
        if (selectedItem != null) {
            setCurrentRendered(false);
            deletePersonInNeedOfCare(selectedItem?.identifier, selectedItem?.historyNumber).then(() => {
                setSelectedItem(null)
                setDefaultChronoIndex(-1)
                fetchHistoryData(selectedItem?.identifier, effectiveDate, null);
                setShowDeleteDialog(false);
            });

        }
    };

    const onLeaveSiteCancel = () => {
        const historyData: HistoryTimelineItemModel[] = [];
        getHistory().forEach(element => {
            historyData.push({
                type: element.type,
                title: element.name,
                url: element.url,
                id: element.id
            })
        })
        console.log(historyChronoItems)
        setHistoryChronoItems(historyData)
        setShowInputErrorDialog(false);
    };

    const onLeaveSiteSubmit = () => {
        setShowInputErrorDialog(false);
        if (selectedHistoryIndex >= 0) {
            updateHistoryUntilIndex(selectedHistoryIndex)
        }
        navigate(leavePath);
    };

    const columnsHistory: ColumnDefinitionType<PersonInNeedOfCareAPI, keyof PersonInNeedOfCareAPI>[] = [
        {
            key: "historyNumber",
            header: "Historiennummer",
        },
        {
            key: "effectiveDate",
            header: "Wirksamkeitsdatum",
        },
        {
            key: "createTimestamp",
            header: "Zeitstempel Erstellung",
        },
        {
            key: "createDescriptionString",
            header: "Beschreibung Erstellung",
        },
        {
            key: "deleteTimestamp",
            header: "Zeitstempel Löschung",
        },
        {
            key: "deleteDescriptionString",
            header: "Beschreibung Löschung",
        },
    ];

    const columnsPersonConnection: ColumnDefinitionType<PersonConnectionAPI, keyof PersonConnectionAPI>[] = [
        {
            key: "caringPersonName",
            header: "Name, Vorname, Geburtsdatum",
        },
        {
            key: "fromUntilString",
            header: "Beziehung Von - Bis",
        },
        {
            key: "statusString",
            header: "Status",
        },
    ];

    const [sortByHistory] = useState<Sort<PersonInNeedOfCareAPI>>({
        key: "historyNumber",
        direction: SortingDirection.Ascending,
    });

    const [sortByPersonConnection] = useState<Sort<PersonConnectionAPI>>({
        key: "personInNeedOfCareName",
        direction: SortingDirection.Descending,
    });

    const caringPersonAllowed = (person: PersonConnectionAPI) => {
        if (user) {
            if (isShowMode) {
                return hasGetPersonDataPermission(user.permissions, person.caringPerson.contractType)
            } else {
                return hasEditPersonDataPermission(user.permissions, person.caringPerson.contractType)
            }
        } else {
            return false;
        }
    }

    const personConnectionAllowed = (person: PersonConnectionAPI) => {
        if (user) {
            if (isShowMode) {
                return hasGetPersonDataPermission(user.permissions, person.caringPerson.contractType) && hasGetPersonDataPermission(user.permissions, person.personInNeedOfCare.contractType)
            } else {
                return hasEditPersonDataPermission(user.permissions, person.caringPerson.contractType) && hasEditPersonDataPermission(user.permissions, person.personInNeedOfCare.contractType)
            }
        } else {
            return false;
        }
    }

    return (
        <PageLayout title={getUserTitle()} actions={isShowMode ? actionsShow : actions}>
            <ConfirmCancelDialog
                showDialog={showDeleteDialog}
                dialogTitle="Bedürftigeneintrag löschen?"
                dialogInfo={`Möchten Sie den Eintrag mit Wirksamkeitsdatum
                    ${selectedItem?.title} wirklich löschen?`}
                onClickCancel={onDeleteCancel}
                onClickSubmit={() => onDeleteSubmit()}
            />
            <InputErrorDialog
                showDialog={showInputErrorDialog}
                dialogTitle="Bedürftigeneintrag löschen?"
                dialogInfo={`Die/Der Bedürftige/r kann nicht gespeichert werden, da das Formular nicht richtig ausgefüllt wurde. Soll die Seite trotzdem verlassen werden?`}
                onClickCancel={onLeaveSiteCancel}
                onClickSubmit={() => onLeaveSiteSubmit()}
            />
            <div className="single-view">
                <HistoryLine
                    items={historyChronoItems}
                    setCurrentRendered={setCurrentRendered}
                    saveAndOpenPath={isShowMode ? undefined : onClickSaveAndOpenPath}
                    errorInput={errorInput}
                    setShowInputErrorDialog={setShowInputErrorDialog}
                    setLeavePath={setLeavePath}
                    setSelectedIndex={setSelectedHistoryIndex}
                    setHistoryChronoItems={setHistoryChronoItems}
                />
                {id && <Timeline
                    items={chronoItems}
                    defaultChronoIndex={defaultChronoIndex}
                    onItemSelected={onTimeLineItemSelected}
                />}
                {(!isShowMode && id && user && isAidUser(user)) && <div className='single-view-delete-button-container'><Button variant="contained" color="primary" className="delete-entry-button" onClick={deleteEntry} sx={{ maxWidth: 1 / 6, }}>
                    <Delete sx={{ mr: 1 }}></Delete>
                    Eintrag löschen
                </Button>
                </div>}
                <EffectiveDateForm
                    savePushed={savePushed}
                    disabled={isShowMode}
                    effectiveDate={effectiveDate}
                    setEffectiveDate={setEffectiveDate}
                    formError={effectiveDate === ""}
                    validation
                />
                <PersonForm
                    savePushed={savePushed}
                    disabled={isShowMode}
                    lastname={lastname}
                    setLastname={setLastname}
                    firstname={firstname}
                    setFirstname={setFirstname}
                    dateOfBirth={dateOfBirth}
                    setDateOfBirth={setDateOfBirth}
                    gender={gender}
                    setGender={setGender}
                    namePrefix={namePrefix}
                    setNamePrefix={setNamePrefix}
                    title={title}
                    setTitle={setTitle}
                    pensionInsuranceNumber={pensionInsuranceNumber}
                    setPensionInsuranceNumber={setPensionInsuranceNumber}
                    formError={errorPerson}
                    validation
                />

                <AddressForm
                    savePushed={savePushed}
                    disabled={isShowMode}
                    street={street}
                    setStreet={setStreet}
                    houseNumber={houseNumber}
                    setHouseNumber={setHouseNumber}
                    postalCode={postalCode}
                    setPostalCode={setPostalCode}
                    location={location}
                    setLocation={setLocation}
                    country={country}
                    setCountry={setCountry}
                    poBox={poBox}
                    poBoxPostalCode={poBoxPostalCode}
                    setPOBox={setPOBox}
                    setPOBoxPostalCode={setPOBoxPostalCode}
                    addressSupplement={addressSupplement}
                    setAddressSupplement={setAddressSupplement}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    fax={fax}
                    setFax={setFax}
                    email={email}
                    setEmail={setEmail}
                    useAddress={useAddress}
                    formError={errorAddress}
                    validation
                />

                <PersonInNeedOfCareForm
                    aid={aid}
                    aidValues={aidValues}
                    aidLevelValues={aidLevelValues}
                    conceptOfOrder1={conceptOfOrder1}
                    conceptOfOrder2={conceptOfOrder2}
                    contractType={contractType}
                    disabled={isShowMode}
                    externalConceptOfOrder1={externalConceptOfOrder1}
                    externalConceptOfOrder2={externalConceptOfOrder2}
                    specialConceptOfOrder1={specialConceptOfOrder1}
                    specialConceptOfOrder2={specialConceptOfOrder2}
                    insurance={insurance}
                    insuranceValues={insuranceValues}
                    savePushed={savePushed}
                    setAid={setAidAndAidLevels}
                    setConceptOfOrder1={setConceptOfOrder1}
                    setConceptOfOrder2={setConceptOfOrder2}
                    setContractType={setContractType}
                    setExternalConceptOfOrder1={setExternalConceptOfOrder1}
                    setExternalConceptOfOrder2={setExternalConceptOfOrder2}
                    setSpecialConceptOfOrder1={setSpecialConceptOfOrder1}
                    setSpecialConceptOfOrder2={setSpecialConceptOfOrder2}
                    setInsurance={setInsurance}
                    setAidLevel={setAidLevel}
                    aidLevel={aidLevel}
                    note={note}
                    setNote={setNote}
                    labelConceptOfOrderOne={labelConceptOfOrderOnePersonInNeedOfCare}
                    labelConceptOfOrderTwo={labelConceptOfOrderTwoPersonInNeedOfCare}
                    labelExternalConceptOfOrderOne={labelExternalConceptOfOrderOnePersonInNeedOfCare}
                    labelExternalConceptOfOrderTwo={labelExternalConceptOfOrderTwoPersonInNeedOfCare}
                    labelSpecialConceptOfOrderOne={labelSpecialConceptOfOrderOnePersonInNeedOfCare}
                    labelSpecialConceptOfOrderTwo={labelSpecialConceptOfOrderTwoPersonInNeedOfCare}
                    useSpecialConceptOfOrder={useSpecialConceptOfOrderPersonInNeedOfCare}
                    user={user}
                    formError={errorPersonInNeedOfCare}
                    validation
                />
                <Divider variant='fullWidth' sx={{ mt: 3, mb: 3 }} ></Divider>
                <div className='single-view-accordion'>
                    <AccordionComponent
                        label="Pflegeperson Beziehungen"
                        defaultExpanded={false}
                        startIcon={<Person></Person>}
                        content={
                            <CrudTable
                                tableData={personConnection}
                                columns={columnsPersonConnection}
                                sortBy={sortByPersonConnection}
                                fetchElementsWithId={fetchAllPersonConnectionForPersonInNeedOfCare}
                                changeFloatingText
                                dataTypeName="Berechnungsgrundlage"
                                personDataTypeName='Pflegeperson'
                                dataFormatter={(element: PersonConnectionAPI) => {
                                    element.caringPersonName = formatPersonName(element.caringPerson.person)
                                    element.personInNeedOfCareName = formatPersonName(element.personInNeedOfCare.person)
                                    element.validityString = formatValidityString(element.validity)
                                    element.fromUntilString = getFromUntilStringFromCalculationBasisList([...element.calculationBasisListRV, ...element.calculationBasisListAV])
                                    element.statusString = formatStatusString(element.status, element.createTransaction.user)
                                    return element;
                                }}
                                callOnPersonConnectionClick={onClickCalculationBasisEdit}
                                callOnCaringPersonClick={onClickCaringPersonEdit}
                                caringPersonPermission={caringPersonAllowed}
                                personConnectionPermission={personConnectionAllowed}
                                callOnAddClick={isShowMode ? undefined : onClickCalculationBasisCreate}
                                callOnPersonAddClick={isShowMode ? undefined : onClickCaringPersonCreate}
                                showGender
                                searchingId={id}
                            />
                        }
                    />
                </div>
                {id &&
                    <div className='single-view-accordion'>
                        <AccordionComponent
                            label="Historie"
                            defaultExpanded={false}
                            startIcon={<Feed></Feed>}
                            content={
                                <CrudTable
                                    tableData={historyData}
                                    columns={columnsHistory}
                                    sortBy={sortByHistory}
                                    fetchElementsWithId={fetchHistoryForPersonInNeedOfCare}
                                    dataTypeName="Historieneintrag"
                                    dataFormatter={(element: PersonInNeedOfCareAPI) => {
                                        element.createTimestamp = formatTimeStampUserString(element.createTimestamp, element.createTransaction.user)
                                        if (element.deleteTimestamp === null || element.deleteTransaction === null) {
                                            if (element.deleteTimestamp === null) {
                                                element.deleteTimestamp = "";
                                            } else {
                                                element.deleteTimestamp = formatTimestampString(element.deleteTimestamp)
                                            }
                                        } else {
                                            element.deleteTimestamp = formatTimeStampUserString(element.deleteTimestamp, element.deleteTransaction.user)
                                        }
                                        element.effectiveDate = formatDateString(element.effectiveDate);
                                        element.createDescriptionString = element.createTransaction.description;
                                        element.deleteDescriptionString = element.deleteTransaction ? element.deleteTransaction?.description : ""
                                        return element;
                                    }}
                                    callOnFindInPageClick={onClickHistoryShow}
                                    hideSearchbar
                                    searchingId={id}
                                />
                            }
                        />
                    </div>
                }
            </div>
        </PageLayout>
    );
}

export default PersonInNeedOfCare;
