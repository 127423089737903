import {
    Drawer,
    Divider,
    ListItemButton,
    ListItemText,
    Toolbar,
    Box,
    List,
    Link,
    Collapse,
    ListItemIcon,
    SvgIcon
} from "@mui/material";
import {
    AccountCircle,
    AdminPanelSettings,
    Assignment,
    AssistWalker,
    Build,
    Business,
    Calculate,
    ExpandLess,
    ExpandMore,
    FileDownload,
    Healing,
    HealthAndSafety,
    Home,
    Inventory,
    MonetizationOn,
    Person,
    SvgIconComponent,
    Update
} from "@mui/icons-material";

import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeSideNav } from "../../redux/side-nav.reducer";
import { } from "../../redux/auth.reducer";
import { Link as RouterLink, useLocation } from "react-router-dom";
import "./side-nav.css";
import { DRAWER_WIDTH } from "../../constants/side-nav";
import { isAidUser } from "../../models/user";

interface Route {
    path: string;
    idx: number;
    name: string;
    icon: SvgIconComponent;
    permissions: string[];
}

function SideNav() {
    const isOpen = useSelector(
        ({ sideNavReducer }: RootState) => sideNavReducer.isOpen
    );

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState<number>();
    const [isAdministrationOpen, setIsAdministrationOpen] =
        useState<boolean>(false);
    const toggleOpenAdministration = () =>
        setIsAdministrationOpen(!isAdministrationOpen);
    const [isInstitutionOpen, setIsInstitutionOpen] =
        useState<boolean>(false);
    const toggleOpenInstitution = () =>
        setIsInstitutionOpen(!isInstitutionOpen);


    const { user } = useSelector(({ authReducer }: RootState) => authReducer);


    const independentRoutes: Route[] = [
        {
            path: "/home",
            idx: 1,
            name: "Home",
            icon: Home,
            permissions: []
        },
        {
            path: "/caring-person",
            idx: 2,
            name: "Pflegeperson suchen",
            icon: Person,
            permissions: ["data_normal:read", "data_employee:read", "data_vip:read"]
        },
        {
            path: "/person-in-need-of-care",
            idx: 3,
            name: "Bedürftige/n suchen",
            icon: AssistWalker,
            permissions: ["data_normal:read", "data_employee:read", "data_vip:read"]
        },
        {
            path: "/process",
            idx: 4,
            name: "Vorgänge suchen",
            icon: Assignment,
            permissions: []
        },
    ];

    const institutionRoutes: Route[] = [
        {
            path: "/aids",
            idx: 11,
            name: "Beihilfestellen anzeigen",
            icon: HealthAndSafety,
            permissions: []
        },
        {
            path: "/insurances",
            idx: 12,
            name: "Versicherungen anzeigen",
            icon: Healing,
            permissions: []
        },
        {
            path: "/pension-institutions",
            idx: 13,
            name: "Versorgungseinrichtungen anzeigen",
            icon: Inventory,
            permissions: []
        },

    ];

    const adminRoutes: Route[] = [
        {
            path: "/user",
            idx: 21,
            name: "Benutzer verwalten",
            icon: AccountCircle,
            permissions: ["user:get", "user_own_institution:get"]
        },
        {
            path: "/institution",
            idx: 22,
            name: "Institutionen verwalten",
            icon: Business,
            permissions: ["institution:edit"]
        },
        {
            path: "/costs",
            idx: 23,
            name: "Mandantenkosten anzeigen",
            icon: MonetizationOn,
            permissions: ["costs:get"]
        },
        {
            path: "/institution/edit/" + user?.institution.id,
            idx: 24,
            name: "Institution bearbeiten",
            icon: Business,
            permissions: ["institution_own:edit"]
        },
        {
            path: "/aid/config/edit/" + user?.institution.id,
            idx: 25,
            name: "Mandanteneinstellungen bearbeiten",
            icon: Build,
            permissions: ["aid_config:edit"]
        },
        {
            path: "/update-calculation-basis",
            idx: 26,
            name: "Berechnungsgrundlagen aktualisieren",
            icon: Update,
            permissions: ["update_calculation_basis:get"]
        },
        {
            path: "/calculation-run",
            idx: 27,
            name: "Beitragsberechnungsläufe starten",
            icon: Calculate,
            permissions: ["calculation:get"]
        },
        {
            path: "/export-calculation-runs",
            idx: 28,
            name: "Berechnungsdetails exportieren",
            icon: FileDownload,
            permissions: ["calculation:get"]
        }
    ];

    useEffect(() => {
        const idx = [...independentRoutes, ...adminRoutes, ...institutionRoutes].find(
            (route) => pathname.includes(route.path)
        )?.idx;
        setSelectedItem(idx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const list = () => (
        <Box sx={{ width: DRAWER_WIDTH, height: "100%" }}>
            <List className="side-nav__flex-content">
                <List component="div" disablePadding>
                    {user && independentRoutes.map(({ idx, path, name, icon, permissions }) => {
                        let hasPermission = permissions.length === 0;
                        permissions.forEach((element) => {
                            hasPermission = hasPermission || user.permissions.includes(element)
                        })
                        if (hasPermission) {
                            return (
                                <Link
                                    component={RouterLink}
                                    to={path}
                                    sx={{ textDecoration: "none" }}
                                    key={idx}
                                >
                                    <ListItemButton
                                        selected={selectedItem === idx}
                                        sx={{ pl: 2, mb: 1 }}
                                        onClick={() => setSelectedItem(idx)}
                                    >
                                        {selectedItem === idx && <ListItemIcon><SvgIcon component={icon} className="filter-white"></SvgIcon></ListItemIcon>}
                                        {selectedItem !== idx && <ListItemIcon><SvgIcon component={icon}></SvgIcon></ListItemIcon>}
                                        <ListItemText primary={name} />
                                    </ListItemButton>
                                </Link>
                            )
                        }
                        return <div></div>
                    })}
                    <Divider className="mt-2"></Divider>
                    <ListItemButton onClick={toggleOpenInstitution} sx={{ pl: 2, mt: 1 }}>
                        <ListItemIcon><Business /></ListItemIcon>
                        <ListItemText primary="Institutionen" />
                        {isInstitutionOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                        in={isInstitutionOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            {
                                user && institutionRoutes.map(({ idx, path, name, icon, permissions }) => {
                                    let hasPermission = permissions.length === 0;
                                    permissions.forEach((element) => {
                                        hasPermission = hasPermission || user.permissions.includes(element)
                                    })
                                    if (hasPermission) {
                                        if ((idx !== 11) || (!isAidUser(user))) {
                                            return (
                                                <Link
                                                    component={RouterLink}
                                                    to={path}
                                                    sx={{ textDecoration: "none" }}
                                                    key={idx}
                                                >
                                                    <ListItemButton
                                                        selected={selectedItem === idx}
                                                        sx={{ pl: 4 }}
                                                        onClick={() => setSelectedItem(idx)}
                                                    >
                                                        {selectedItem === idx && <ListItemIcon><SvgIcon component={icon} className="filter-white"></SvgIcon></ListItemIcon>}
                                                        {selectedItem !== idx && <ListItemIcon><SvgIcon component={icon}></SvgIcon></ListItemIcon>}
                                                        <ListItemText primary={name} />
                                                    </ListItemButton>
                                                </Link>
                                            )
                                        } else {
                                            return (<div></div>);
                                        }
                                    }
                                    return <div></div>
                                })
                            }
                        </List>
                    </Collapse>

                    <Divider className="mt-2"></Divider>
                    {user && <ListItemButton onClick={toggleOpenAdministration} sx={{ pl: 2, mt: 1 }}>
                        <ListItemIcon><AdminPanelSettings /></ListItemIcon>
                        <ListItemText primary="Administration" />
                        {isAdministrationOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    }
                    <Collapse
                        in={isAdministrationOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List component="div" disablePadding>
                            {
                                user && adminRoutes.map(({ idx, path, name, icon, permissions }) => {
                                    let hasPermission = permissions.length === 0;
                                    permissions.forEach((element) => {
                                        hasPermission = hasPermission || user.permissions.includes(element)
                                    })
                                    if (hasPermission) {
                                        return (<Link
                                            component={RouterLink}
                                            to={path}
                                            sx={{ textDecoration: "none" }}
                                            key={idx}
                                        >
                                            <ListItemButton
                                                selected={selectedItem === idx}
                                                sx={{ pl: 4 }}
                                                onClick={() => setSelectedItem(idx)}
                                            >
                                                {selectedItem === idx && <ListItemIcon><SvgIcon component={icon} className="filter-white"></SvgIcon></ListItemIcon>}
                                                {selectedItem !== idx && <ListItemIcon><SvgIcon component={icon}></SvgIcon></ListItemIcon>}
                                                <ListItemText primary={name} />
                                            </ListItemButton>
                                        </Link>
                                        )
                                    }
                                    return <div></div>
                                })
                            }
                        </List>
                    </Collapse>
                </List>
            </List>
        </Box>
    );
    return (
        <div>
            <React.Fragment key="left">
                <Drawer
                    variant="persistent"
                    anchor="left"
                    open={isOpen}
                    onClose={() => dispatch(closeSideNav())}
                >
                    <Toolbar />
                    {list()}
                    <p className="side-nav__version">
                        AidPlus Version: {process.env.REACT_APP_VERSION}
                    </p>
                </Drawer>
            </React.Fragment>
        </div>
    );
}

export default SideNav;
